import {
    Circle,
    Rect,
    Line,
    Arrow,
    RegularPolygon,
    Ellipse,
} from "react-konva";
import { useTheme } from "@mui/material/styles";
import { useState, useCallback } from "react";
import { handleDragStart, handleDragMove, handleDragEnd } from "./handleDrag";
import chroma from "chroma-js";
import { Html } from "react-konva-utils";
// import SelectionBox from "./SelectionBox";

const Drawing = ({
    fieldDimensions,
    state,
    setState,
    hoveredId,
    editingMode,
    undoStack,
    setUndoStack,
    redoStack,
    setRedoStack,
    previewedShape,
    setPreviewedShape,
    contextMenu,
    setContextMenu,
    losLocation,
    setLosLocation,
    direction,
}) => {
    const theme = useTheme();
    const [draggingShapeId, setDraggingShapeId] = useState(null); // Track which shape is being dragged
    const [dragOrigin, setDragOrigin] = useState(null);

    const onClick = useCallback(
        ({ e, shape }) => {
            if (e.evt.button === 2) return; // Ignore right clicks

            setContextMenu(null); // Clear the context menu if left-clicked
            if (e.evt.ctrlKey) {
                shape.selected = !shape.selected;
                setState({
                    ...state,
                    shapes: state.shapes.map((s) => {
                        if (s.id === shape.id) {
                            return shape;
                        }
                        return s;
                    }),
                });
            } else {
                setState({
                    ...state,
                    shapes: state.shapes.map((s) => {
                        if (s.id === shape.id) {
                            return { ...shape, selected: true };
                        }
                        return { ...s, selected: false };
                    }),
                });
            }
        },
        [setContextMenu, state, setState]
    );

    const onContextMenu = useCallback(
        ({ e, shape }) => {
            // Ensure context menu state is updated properly
            setContextMenu({
                x: e.evt.clientX,
                y: e.evt.clientY,
                shape,
            });
        },
        [setContextMenu]
    );

    const renderShape = ({
        shape,
        fill,
        draggable,
        undoStack,
        setUndoStack,
        redoStack,
        setRedoStack,
        isPreviewed,
    }) => {
        if (!shape) return;

        const isSelectedorHovered =
            (state.editing && shape.selected) || shape.id === hoveredId;

        if (shape.shape === "circle") {
            const x = fieldDimensions.width * (shape.x / 65);
            const y = fieldDimensions.height * (shape.y / 150);
            const radius = (shape.radius * fieldDimensions.width) / 65;

            return (
                <>
                    <>
                        {!(
                            previewedShape && shape.id === previewedShape.id
                        ) && (
                            <Html
                                groupProps={{
                                    x,
                                    y,
                                    offsetX: radius,
                                    offsetY: radius,
                                }}
                            >
                                <textarea
                                    value={shape.label}
                                    onChange={(e) => {
                                        const newLabel = e.target.value;
                                        if (newLabel.length > 2) return;
                                        setRedoStack([]);
                                        setUndoStack([
                                            ...undoStack,
                                            {
                                                shapes: state.shapes,
                                                direction: direction.current,
                                                losLocation,
                                                formation: state.formation,
                                                play: state.play,
                                                vsFormation: state.vsFormation,
                                                vsPlay: state.vsPlay,
                                                notes: state.notes,
                                                positionGroup:
                                                    state.positionGroup,
                                                fileType: state.fileType,
                                            },
                                        ]);
                                        setState({
                                            ...state,
                                            shapes: state.shapes.map((s) => {
                                                if (s.id === shape.id) {
                                                    return {
                                                        ...shape,
                                                        label: newLabel,
                                                    };
                                                }
                                                return s;
                                            }),
                                        });
                                    }}
                                    style={{
                                        width: `${radius * 2}px`,
                                        height: `${radius * 2}px`,
                                        border: "none",
                                        padding: "0px",
                                        margin: "0px",
                                        background: "none",
                                        outline: "none",
                                        resize: "none",
                                        color: "black",
                                        display: "flex",
                                        fontWeight: "bold",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        textAlign: "center",
                                        lineHeight: `${radius * 2}px`, // Center text vertically
                                        fontSize:
                                            shape.label.length === 1
                                                ? radius * 2.2
                                                : radius * 1.6,
                                        whiteSpace: "nowrap", // Prevent text from wrapping
                                    }}
                                />
                            </Html>
                        )}
                    </>

                    <Circle
                        key={shape.id}
                        id={shape.id}
                        x={x}
                        y={y}
                        draggable={
                            state.editing &&
                            editingMode === "selecting" &&
                            draggable
                        }
                        onDragStart={(e) =>
                            handleDragStart({
                                e,
                                state,
                                setState,
                                setDragOrigin,
                                shape,
                                setDraggingShapeId,
                                undoStack,
                                setUndoStack,
                                setRedoStack,
                                redoStack,
                                losLocation,
                                direction,
                            })
                        }
                        onDragMove={(e) =>
                            handleDragMove({
                                e,
                                shape,
                                dragOrigin,
                                draggingShapeId,
                                setDragOrigin,
                                fieldDimensions,
                                state,
                                setState,
                                undoStack,
                                setUndoStack,
                            })
                        }
                        onDragEnd={() =>
                            handleDragEnd({
                                state,
                                setState,
                                setDragOrigin,
                                setDraggingShapeId,
                                undoStack,
                                setUndoStack,
                            })
                        }
                        radius={radius}
                        stroke={
                            isSelectedorHovered
                                ? theme.palette.primary.main
                                : shape.border && shape.color
                        }
                        strokeWidth={
                            isSelectedorHovered
                                ? radius / 3
                                : shape.border && radius / 7
                        }
                        fill={shape.fill === "full" && shape.color}
                        fillLinearGradientStartPoint={{
                            x: -radius,
                            y: 0,
                        }}
                        fillLinearGradientEndPoint={{
                            x: radius,
                            y: 0,
                        }}
                        fillLinearGradientColorStops={
                            shape.fill === "left"
                                ? [
                                      0,
                                      shape.color,
                                      0.5,
                                      shape.color,
                                      0.5,
                                      "transparent",
                                      1,
                                      "transparent",
                                  ]
                                : shape.fill === "right"
                                ? [
                                      0,
                                      "transparent",
                                      0.5,
                                      "transparent",
                                      0.5,
                                      shape.color,
                                      1,
                                      shape.color,
                                  ]
                                : shape.fill === "middle"
                                ? [
                                      0,
                                      "transparent",
                                      0.25,
                                      "transparent",
                                      0.25,
                                      shape.color,
                                      0.75,
                                      shape.color,
                                      0.75,
                                      "transparent",
                                      1,
                                      "transparent",
                                  ]
                                : []
                        }
                        onClick={(e) => state.editing && onClick({ e, shape })}
                        onContextMenu={(e) =>
                            state.editing && onContextMenu({ e, shape })
                        }
                    />
                </>
            );
        } else if (shape.shape === "rect") {
            const x = fieldDimensions.width * (shape.x / 65);
            const y = fieldDimensions.height * (shape.y / 150);
            const radius = (shape.radius * fieldDimensions.width) / 65;

            return (
                <>
                    <>
                        {!(
                            previewedShape && shape.id === previewedShape.id
                        ) && (
                            <Html
                                groupProps={{
                                    x,
                                    y,
                                }}
                            >
                                <textarea
                                    value={shape.label}
                                    onChange={(e) => {
                                        const newLabel = e.target.value;
                                        if (newLabel.length > 2) return;
                                        setState({
                                            ...state,
                                            shapes: state.shapes.map((s) => {
                                                if (s.id === shape.id) {
                                                    return {
                                                        ...shape,
                                                        label: newLabel,
                                                    };
                                                }
                                                return s;
                                            }),
                                        });
                                    }}
                                    style={{
                                        width: `${radius * 2}px`,
                                        height: `${radius * 2}px`,
                                        border: "none",
                                        padding: "0px",
                                        margin: "0px",
                                        background: "none",
                                        outline: "none",
                                        resize: "none",
                                        color: "black",
                                        display: "flex",
                                        fontWeight: "bold",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        textAlign: "center",
                                        lineHeight: `${radius * 2}px`, // Center text vertically
                                        fontSize:
                                            shape.label.length === 1
                                                ? radius * 2.2
                                                : radius * 1.6,
                                        whiteSpace: "nowrap", // Prevent text from wrapping
                                    }}
                                />
                            </Html>
                        )}
                    </>
                    <Rect
                        key={shape.id}
                        id={shape.id}
                        x={x}
                        y={y}
                        draggable={
                            state.editing &&
                            editingMode === "selecting" &&
                            draggable
                        }
                        onDragStart={(e) =>
                            handleDragStart({
                                e,
                                state,
                                setState,
                                setDragOrigin,
                                shape,
                                setDraggingShapeId,
                                undoStack,
                                setUndoStack,
                                redoStack,
                                setRedoStack,
                                losLocation,
                                direction,
                            })
                        }
                        onDragMove={(e) =>
                            handleDragMove({
                                e,
                                shape,
                                dragOrigin,
                                draggingShapeId,
                                setDragOrigin,
                                fieldDimensions,
                                state,
                                setState,
                                undoStack,
                                setUndoStack,
                            })
                        }
                        onDragEnd={() =>
                            handleDragEnd({
                                state,
                                setState,
                                setDragOrigin,
                                setDraggingShapeId,
                                undoStack,
                                setUndoStack,
                                redoStack,
                                setRedoStack,
                            })
                        }
                        width={radius * 2}
                        height={radius * 2}
                        stroke={
                            isSelectedorHovered
                                ? theme.palette.primary.main
                                : shape.border && shape.color
                        }
                        strokeWidth={
                            isSelectedorHovered
                                ? radius / 3
                                : shape.border && radius / 7
                        }
                        fill={shape.fill === "full" && shape.color}
                        fillLinearGradientStartPoint={
                            (shape.fill === "left" ||
                                shape.fill === "middle" ||
                                shape.fill === "right") && {
                                x: 0,
                                y: 0,
                            }
                        }
                        fillLinearGradientEndPoint={
                            (shape.fill === "left" ||
                                shape.fill === "middle" ||
                                shape.fill === "right") && {
                                x: radius * 2,
                                y: 0,
                            }
                        }
                        fillLinearGradientColorStops={
                            shape.fill === "left"
                                ? [
                                      0,
                                      shape.color,
                                      0.5,
                                      shape.color,
                                      0.5,
                                      "transparent",
                                      1,
                                      "transparent",
                                  ]
                                : shape.fill === "right"
                                ? [
                                      0,
                                      "transparent",
                                      0.5,
                                      "transparent",
                                      0.5,
                                      shape.color,
                                      1,
                                      shape.color,
                                  ]
                                : shape.fill === "middle"
                                ? [
                                      0,
                                      "transparent",
                                      0.25,
                                      "transparent",
                                      0.25,
                                      shape.color,
                                      0.75,
                                      shape.color,
                                      0.75,
                                      "transparent",
                                      1,
                                      "transparent",
                                  ]
                                : null
                        }
                        onClick={(e) => onClick({ e, shape })}
                        onContextMenu={(e) => onContextMenu({ e, shape })}
                    />
                </>
            );
        } else if (shape.shape === "text") {
            const x = fieldDimensions.width * (shape.x / 65);
            const y = fieldDimensions.height * (shape.y / 150);

            return (
                <>
                    <>
                        <Html
                            groupProps={{
                                x,
                                y,
                            }}
                        >
                            <textarea
                                value={shape.text}
                                onChange={(e) => {
                                    const newText = e.target.value;
                                    setState({
                                        ...state,
                                        shapes: state.shapes.map((s) => {
                                            if (s.id === shape.id) {
                                                return {
                                                    ...shape,
                                                    text: newText,
                                                };
                                            }
                                            return s;
                                        }),
                                    });
                                }}
                                onDoubleClick={(e) => {
                                    alert("dbl");
                                }}
                                style={{
                                    border: "1px solid black",
                                    padding: "0px",
                                    margin: "0px",
                                    background: "none",
                                    outline: "none",
                                    resize: "none",
                                    color: "black",
                                    fontSize: "14px",
                                    pointerEvents:
                                        previewedShape &&
                                        shape.id === previewedShape.id
                                            ? "none"
                                            : "auto",
                                }}
                            />
                        </Html>
                    </>
                </>
            );
        } else if (shape.shape === "triangle") {
            const x = fieldDimensions.width * (shape.x / 65);
            const y = fieldDimensions.height * (shape.y / 150);
            const radius = (shape.radius * fieldDimensions.width) / 65;

            return (
                <>
                    <>
                        {!(
                            previewedShape && shape.id === previewedShape.id
                        ) && (
                            <Html
                                groupProps={{
                                    x,
                                    y,
                                    offsetX: radius,
                                    offsetY: radius,
                                }}
                            >
                                <textarea
                                    id={shape.id}
                                    key={shape.id}
                                    value={shape.label}
                                    disabled={shape.id === previewedShape?.id}
                                    onChange={(e) => {
                                        const newLabel = e.target.value;
                                        if (newLabel.length > 2) return;
                                        setState({
                                            ...state,
                                            shapes: state.shapes.map((s) => {
                                                if (s.id === shape.id) {
                                                    return {
                                                        ...shape,
                                                        label: newLabel,
                                                    };
                                                }
                                                return s;
                                            }),
                                        });
                                    }}
                                    style={{
                                        width: `${radius * 2}px`,
                                        height: `${radius * 2}px`,
                                        border: "none",
                                        padding: "0px",
                                        margin: "0px",
                                        background: "none",
                                        outline: "none",
                                        resize: "none",
                                        color: "black",
                                        display: "flex",
                                        fontWeight: "bold",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        textAlign: "center",
                                        lineHeight: `${radius * 2}px`, // Center text vertically
                                        fontSize:
                                            shape.label.length === 1
                                                ? radius * 2
                                                : radius * 1.3,
                                        whiteSpace: "nowrap", // Prevent text from wrapping
                                    }}
                                />
                            </Html>
                        )}
                    </>
                    <RegularPolygon
                        key={shape.id}
                        id={shape.id}
                        x={x}
                        y={y}
                        draggable={
                            state.editing &&
                            editingMode === "selecting" &&
                            draggable
                        }
                        rotationDeg={direction.current === "south" ? 180 : 0}
                        onDragStart={(e) =>
                            handleDragStart({
                                e,
                                state,
                                setState,
                                setDragOrigin,
                                shape,
                                setDraggingShapeId,
                                undoStack,
                                setUndoStack,
                                redoStack,
                                setRedoStack,
                                losLocation,
                                direction,
                            })
                        }
                        onDragMove={(e) =>
                            handleDragMove({
                                e,
                                shape,
                                dragOrigin,
                                draggingShapeId,
                                setDragOrigin,
                                fieldDimensions,
                                state,
                                setState,
                                undoStack,
                                setUndoStack,
                            })
                        }
                        onDragEnd={() =>
                            handleDragEnd({
                                state,
                                setState,
                                setDragOrigin,
                                setDraggingShapeId,
                                undoStack,
                                setUndoStack,
                                redoStack,
                                setRedoStack,
                            })
                        }
                        radius={radius * 1.33}
                        sides="3"
                        stroke={
                            isSelectedorHovered
                                ? theme.palette.primary.main
                                : shape.border && shape.color
                        }
                        strokeWidth={
                            isSelectedorHovered
                                ? radius / 3
                                : shape.border && radius / 7
                        }
                        fill={shape.fill === "full" && shape.color}
                        fillLinearGradientStartPoint={
                            shape.fill === "left" ||
                            shape.fill === "middle" ||
                            shape.fill === "right"
                                ? { x: -radius, y: 0 }
                                : undefined
                        }
                        fillLinearGradientEndPoint={
                            shape.fill === "left" ||
                            shape.fill === "middle" ||
                            shape.fill === "right"
                                ? { x: radius, y: 0 }
                                : undefined
                        }
                        fillLinearGradientColorStops={
                            shape.fill === "left"
                                ? [
                                      0,
                                      shape.color,
                                      0.5,
                                      shape.color,
                                      0.5,
                                      "transparent",
                                      1,
                                      "transparent",
                                  ]
                                : shape.fill === "right"
                                ? [
                                      0,
                                      "transparent",
                                      0.5,
                                      "transparent",
                                      0.5,
                                      shape.color,
                                      1,
                                      shape.color,
                                  ]
                                : shape.fill === "middle"
                                ? [
                                      0,
                                      "transparent",
                                      0.25,
                                      "transparent",
                                      0.25,
                                      shape.color,
                                      0.75,
                                      shape.color,
                                      0.75,
                                      "transparent",
                                      1,
                                      "transparent",
                                  ]
                                : []
                        }
                        onClick={(e) => onClick({ e, shape })}
                        onContextMenu={(e) => onContextMenu({ e, shape })}
                    />
                </>
            );
        } else if (shape.shape === "line") {
            const radius = (shape.radius * fieldDimensions.width) / 65;

            let points = shape.points.map((point, i) => {
                if (i % 2 === 0) {
                    // x coordinate
                    return fieldDimensions.width * (point / 65);
                } else {
                    // y coordinate
                    return fieldDimensions.height * (point / 150);
                }
            });
            const lastPoint = points.slice(-2);
            const secondLastPoint = points.slice(-4, -2);
            const angle = Math.atan2(
                lastPoint[1] - secondLastPoint[1],
                lastPoint[0] - secondLastPoint[0]
            );

            if (shape.head === "flat") {
                // Calculate perpendicular line
                const perpendicularAngle = angle + Math.PI / 2;
                const offsetX =
                    fieldDimensions.width *
                    (0.5 / 65) *
                    Math.cos(perpendicularAngle);
                const offsetY =
                    fieldDimensions.width *
                    (0.5 / 65) *
                    Math.sin(perpendicularAngle);

                if (!previewedShape || previewedShape.id === shape.id) {
                    points.push(
                        lastPoint[0] + offsetX,
                        lastPoint[1] + offsetY
                    ) &&
                        points.push(
                            lastPoint[0] - offsetX,
                            lastPoint[1] - offsetY
                        );
                }
            }
            let SHAPE = Line;
            if (shape.head === "arrow") {
                SHAPE = Arrow;
            }

            if (shape.jagged) {
                let jaggedPoints = [];
                for (let i = 0; i < points.length - 2; i += 2) {
                    const x1 = points[i];
                    const y1 = points[i + 1];
                    const x2 = points[i + 2];
                    const y2 = points[i + 3];
                    const segmentLength = Math.sqrt(
                        Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2)
                    );
                    const numJags = Math.floor(segmentLength / 10); // Adjust the jaggedness by changing the divisor
                    const xIncrement = (x2 - x1) / numJags;
                    const yIncrement = (y2 - y1) / numJags;

                    for (let j = 0; j < numJags; j++) {
                        const x = x1 + xIncrement * j;
                        const y = y1 + yIncrement * j;
                        if (j % 2 === 0) {
                            jaggedPoints.push(x + 5, y + 5); // Adjust the jaggedness by changing the offset
                        } else {
                            jaggedPoints.push(x - 5, y - 5); // Adjust the jaggedness by changing the offset
                        }
                    }
                }
                jaggedPoints.push(
                    points[points.length - 2],
                    points[points.length - 1]
                );
                points = jaggedPoints;
            }

            return (
                <SHAPE
                    key={shape.id}
                    id={shape.id}
                    draggable={
                        state.editing &&
                        editingMode === "selecting" &&
                        draggable
                    }
                    dash={shape.dashed && [radius * 1, radius * 2]}
                    points={points}
                    stroke={
                        (state.editing && shape.selected) ||
                        shape.id === hoveredId
                            ? theme.palette.primary.main
                            : shape.color
                    }
                    onClick={(e) => onClick({ e, shape })}
                    onDragStart={(e) =>
                        handleDragStart({
                            e,
                            state,
                            setState,
                            setDragOrigin,
                            shape,
                            setDraggingShapeId,
                            undoStack,
                            setUndoStack,
                            redoStack,
                            setRedoStack,
                            losLocation,
                            direction,
                        })
                    }
                    onDragMove={(e) =>
                        handleDragMove({
                            e,
                            shape,
                            dragOrigin,
                            draggingShapeId,
                            setDragOrigin,
                            fieldDimensions,
                            state,
                            setState,
                            undoStack,
                            setUndoStack,
                        })
                    }
                    onDragEnd={() =>
                        handleDragEnd({
                            state,
                            setState,
                            setDragOrigin,
                            setDraggingShapeId,
                            undoStack,
                            setUndoStack,
                        })
                    }
                    strokeWidth={radius}
                    lineCap="round"
                    lineJoin="round"
                    globalCompositeOperation="source-over"
                />
            );
        } else if (shape.shape === "rectZone" || shape.shape === "ovalZone") {
            let Shape = Rect;

            let x = fieldDimensions.width * (shape.x / 65);
            let y = fieldDimensions.height * (shape.y / 150);
            let width = shape.width * (fieldDimensions.width / 65);
            let height = shape.height * (fieldDimensions.height / 150);
            if (shape.shape === "ovalZone") {
                Shape = Ellipse;
                x = x + (shape.width * (fieldDimensions.width / 65)) / 2;
                y = y + (shape.height * (fieldDimensions.height / 150)) / 2;
            }

            return (
                <Shape
                    key={shape.id}
                    id={shape.id}
                    x={x}
                    y={y}
                    width={width}
                    height={height}
                    draggable={
                        state.editing &&
                        editingMode === "selecting" &&
                        draggable
                    }
                    onDragStart={(e) =>
                        handleDragStart({
                            e,
                            state,
                            setState,
                            setDragOrigin,
                            shape,
                            setDraggingShapeId,
                            undoStack,
                            setUndoStack,
                            redoStack,
                            setRedoStack,
                            losLocation,
                            direction,
                        })
                    }
                    onDragMove={(e) =>
                        handleDragMove({
                            e,
                            shape,
                            dragOrigin,
                            draggingShapeId,
                            setDragOrigin,
                            fieldDimensions,
                            state,
                            setState,
                            undoStack,
                            setUndoStack,
                        })
                    }
                    onDragEnd={() =>
                        handleDragEnd({
                            state,
                            setState,
                            setDragOrigin,
                            setDraggingShapeId,
                            undoStack,
                            setUndoStack,
                            redoStack,
                            setRedoStack,
                        })
                    }
                    fill={chroma(shape.color).alpha(0.5).css()}
                    stroke={
                        isSelectedorHovered
                            ? theme.palette.primary.main
                            : shape.color
                    }
                    strokeWidth={
                        isSelectedorHovered
                            ? fieldDimensions.width * (0.35 / 65)
                            : fieldDimensions.width * (0.25 / 65)
                    }
                    dash={[10, 5]}
                    onClick={(e) => onClick({ e, shape })}
                    onContextMenu={(e) => onContextMenu({ e, shape })}
                />
            );
        } else {
            return null;
        }
    };

    let canRenderPreviewedShape = Boolean(previewedShape);
    if (
        canRenderPreviewedShape &&
        hoveredId &&
        previewedShape.shape === "line" &&
        hoveredId !== previewedShape.id
    ) {
        canRenderPreviewedShape = false;
    }

    let shapesToRender =
        state.shapes.length === 0
            ? []
            : state.shapes.map((shape) => ({
                  shape,
                  fill: shape.color,
                  draggable: shape.selected || shape.id === hoveredId,
                  undoStack,
                  setUndoStack,
                  redoStack,
                  setRedoStack,
                  isPreviewed: false,
              }));
    canRenderPreviewedShape &&
        shapesToRender.push({
            shape: previewedShape,
            fill: previewedShape.fill,
            draggable: false,
            undoStack,
            setUndoStack,
            redoStack,
            setRedoStack,
            isPreviewed: true,
        });

    shapesToRender.sort((a, b) => {
        const aIsZone =
            a.shape.shape === "rectZone" || a.shape.shape === "ovalZone";
        const bIsZone =
            b.shape.shape === "rectZone" || b.shape.shape === "ovalZone";
        return bIsZone - aIsZone;
    });

    return <>{shapesToRender.map((shape) => renderShape(shape))}</>;
};

export default Drawing;
