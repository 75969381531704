const handleMouseUp = ({
    e,
    state,
    setState,
    isDrawing,
    editingMode,
    lasooSelection,
    setLasooSelection,
    fieldDimensions,
    redoStack,
    setRedoStack,
    contextMenu,
    previewedShape,
    setPreviewedShape,
    hoveredId,
    isAddingZone,
    setUndoStack,
    undoStack,
    direction,
    losLocation,
}) => {
    if (!state.editing) return;
    if (e.evt.button === 2) return; // Ignore right clicks

    if (state.editing && editingMode === "selecting") {
        if (!lasooSelection.isSelecting) {
            const stage = e.target.getStage();
            const shape = stage.getIntersection(stage.getPointerPosition());
            if (!shape || !shape.id()) {
                setState({
                    ...state,
                    shapes: state.shapes.map((s) => {
                        return { ...s, selected: false };
                    }),
                });
            }
        } else if (!contextMenu) {
            const { x, y, width, height } = lasooSelection.selectionRect;
            const scaledX = x * (65 / fieldDimensions.width);
            const scaledY = y * (150 / fieldDimensions.height);
            const scaledWidth = width * (65 / fieldDimensions.width);
            const scaledHeight = height * (150 / fieldDimensions.height);

            const selectedShapes = state.shapes.filter((shape) => {
                if (
                    shape.shape === "circle" ||
                    shape.shape === "rect" ||
                    shape.shape === "triangle"
                ) {
                    return (
                        shape.x > scaledX &&
                        shape.x < scaledX + scaledWidth &&
                        shape.y > scaledY &&
                        shape.y < scaledY + scaledHeight
                    );
                } else if (shape.shape === "line") {
                    return shape.points.some(
                        (point, index) =>
                            index % 2 === 0 &&
                            point > scaledX &&
                            point < scaledX + scaledWidth &&
                            shape.points[index + 1] > scaledY &&
                            shape.points[index + 1] < scaledY + scaledHeight
                    );
                } else if (
                    shape.shape === "rectZone" ||
                    shape.shape === "ovalZone"
                ) {
                    return (
                        shape.x > scaledX &&
                        shape.x < scaledX + scaledWidth &&
                        shape.y > scaledY &&
                        shape.y < scaledY + scaledHeight
                    );
                } else {
                    return false; // should never reach here
                }
            });
            setState({
                ...state,
                shapes: state.shapes.map((shape) => {
                    return {
                        ...shape,
                        selected: selectedShapes.some(
                            (selectedShape) => selectedShape.id === shape.id
                        ),
                    };
                }),
            });
        }
        setLasooSelection({
            startPos: null,
            isSelecting: false,
            selectionRect: null,
        });
    } else if (
        state.editing &&
        editingMode === "addingLine" &&
        isDrawing.current
    ) {
        isDrawing.current = false;
    } else if (
        state.editing &&
        (editingMode === "addingRectZone" || editingMode === "addingOvalZone")
    ) {
        isAddingZone.current = false;
        const lastShapes = state.shapes;
        setUndoStack([
            ...undoStack,
            {
                shapes: lastShapes,
                direction: direction.current,
                losLocation,
                formation: state.formation,
                play: state.play,
                vsFormation: state.vsFormation,
                vsPlay: state.vsPlay,
                notes: state.notes,
                positionGroup: state.positionGroup,
                fileType: state.fileType,
            },
        ]);
        setRedoStack([]);
        setState({
            ...state,
            shapes: [
                ...state.shapes,
                {
                    shape: previewedShape.shape,
                    id: previewedShape.id,
                    selected: false,
                    color: previewedShape.color,
                    x: previewedShape.x,
                    y: previewedShape.y,
                    width: previewedShape.width,
                    height: previewedShape.height,
                },
            ],
        });
        setPreviewedShape(null);
    }
};

export default handleMouseUp;
