export default function concatLabel({ formation, play, vsFormation, vsPlay }) {
    let label = `${formation} ${play}${
        vsFormation || vsPlay ? " vs " : ""
    }${vsFormation} ${vsPlay}`.replace(/ +(?= )/g, "");

    if (label === " ") {
        label = "Untitled";
    }
    return label;
}
