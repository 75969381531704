import { Box, Button, MenuItem, Menu } from "@mui/material";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import {
    IconPointer,
    IconCircleSquare,
    IconLineDashed,
    IconArrowNarrowRightDashed,
    IconArrowNarrowRight,
    IconLetterT,
    IconLetterI,
    IconCircle,
    IconSquare,
    IconTriangle,
    IconArrowsLeftRight,
    IconLetterCase,
} from "@tabler/icons-react";
import {
    IconCloudCheck,
    IconCloudUpload,
    IconLine,
    IconArrowsUpDown,
} from "@tabler/icons-react";
import CircularProgress from "@mui/material/CircularProgress";
import { useState } from "react";
import DashedFlatArrow from "../../../assets/dashed-flat-arrow.png";
import ZigZagLine from "../../../assets/zigzag-line.png";
import { v4 as uuidv4 } from "uuid";
import { flipPlayHorizontal, flipPlayVertical } from "./flipPlay";
import IconRectOvalZone from "../../../assets/rect-oval-zone.png";
import IconRectZone from "../../../assets/rect-zone.png";
import IconOvalZone from "../../../assets/oval-zone.png";

export default function EditMenu({
    parentState,
    setParentState,
    undoStack,
    setUndoStack,
    redoStack,
    setRedoStack,
    setPreviewedShape,
    isDrawing,
    isAddingLine,
    anchoredPreviewedLine,
    losLocation,
    setLosLocation,
    direction,
    stageDimensions,
    saveState,
    setSaveState,
    savePlay,
}) {
    const resetControls = () => {
        setPreviewedShape(null);
        isDrawing.current = false;
        isAddingLine.current = false;
        anchoredPreviewedLine.current = false;
        setParentState({
            ...parentState,
            editingMode: "selecting",
            addingVariant: null,
        });
    };

    const handleUndo = () => {
        resetControls();
        if (undoStack.length > 0) {
            const lastState = undoStack.pop();
            setUndoStack([...undoStack]);
            setRedoStack([
                ...redoStack,
                {
                    shapes: parentState.shapes,
                    direction: direction.current,
                    losLocation,
                    formation: parentState.formation,
                    play: parentState.play,
                    vsFormation: parentState.vsFormation,
                    vsPlay: parentState.vsPlay,
                    notes: parentState.notes,
                    positionGroup: parentState.positionGroup,
                    fileType: parentState.fileType,
                },
            ]);
            direction.current = lastState.direction;
            setLosLocation(lastState.losLocation);
            setParentState({
                ...parentState,
                shapes: lastState.shapes,
                notes: lastState.notes,
                formation: lastState.formation,
                play: lastState.play,
                vsFormation: lastState.vsFormation,
                vsPlay: lastState.vsPlay,
            });
        }
    };

    const handleRedo = () => {
        resetControls();
        if (redoStack.length > 0) {
            const lastState = redoStack.pop();
            setRedoStack([...redoStack]);
            setUndoStack([
                ...undoStack,
                {
                    shapes: parentState.shapes,
                    direction: direction.current,
                    losLocation,
                    formation: parentState.formation,
                    play: parentState.play,
                    vsFormation: parentState.vsFormation,
                    vsPlay: parentState.vsPlay,
                    notes: parentState.notes,
                    positionGroup: parentState.positionGroup,
                    fileType: parentState.fileType,
                },
            ]);
            direction.current = lastState.direction;
            setLosLocation(lastState.losLocation);
            setParentState({
                ...parentState,
                shapes: lastState.shapes,
            });
        }
    };

    const [dropdown, setDropdown] = useState(null);

    return (
        <Box
            display="flex"
            flexDirection="row"
            width={stageDimensions.width + 64}
            height="64px"
            bgcolor="#93ebd4"
            marginLeft="-64px"
            borderTop="1px solid black"
            borderRight="1px solid black"
        >
            <Box
                display="flex"
                flexDirection="column"
                alignItems="left"
                justifyContent="left"
                height="64px"
                width="64px"
                border="1px solid black"
                borderTop="none"
            >
                {saveState === "saving" && <CircularProgress />}
                {saveState !== "saving" && (
                    <Box>
                        <Button
                            variant="outlined"
                            color={undoStack.length > 0 ? "grey" : "success"}
                            disabled={undoStack.length === 0}
                            onClick={() => setSaveState("confirming")}
                            style={{
                                height: "100%",
                                width: "48px",
                                border: "none",
                            }}
                        >
                            {undoStack.length === 0 ? (
                                <IconCloudCheck height="48px" width="48px" />
                            ) : (
                                <IconCloudUpload height="48px" width="48px" />
                            )}
                        </Button>
                    </Box>
                )}
            </Box>
            <Box width="64px" height="64px" borderRight="1px solid black">
                <Button
                    style={{
                        bgcolor: "grey",
                        width: "64px",
                        height: "64px",
                        color: undoStack.length > 0 ? "black" : null,
                        border: "none",
                    }}
                    disabled={!undoStack.length > 0}
                    onClick={handleUndo}
                >
                    <UndoIcon color={undoStack.length > 0 ? "black" : "grey"} />
                </Button>
            </Box>{" "}
            <Box width="64px" height="64px" borderRight="1px solid black">
                <Button
                    style={{
                        bgcolor: "grey",
                        width: "64px",
                        height: "64px",
                        color: redoStack.length > 0 ? "black" : null,
                        border: "none",
                    }}
                    disabled={!redoStack.length > 0}
                    onClick={handleRedo}
                >
                    <RedoIcon color={redoStack.length > 0 ? "black" : "grey"} />
                </Button>
            </Box>{" "}
            <Box width="64px" height="64px" borderRight="1px solid black">
                <Button
                    style={{
                        bgcolor: "grey",
                        width: "64px",
                        height: "64px",
                        color: "black",
                        border: "none",
                    }}
                    onClick={() => {
                        setParentState({
                            ...parentState,
                            editingMode: "selecting",
                        });
                        resetControls();
                    }}
                >
                    <IconPointer />
                </Button>
            </Box>
            <Box width="64px" height="64px" borderRight="1px solid black">
                <Button
                    variant="outlined"
                    style={{
                        bgcolor: "grey",
                        width: "64px",
                        height: "64px",
                        color: "black",
                        border: "none",
                    }}
                    onClose={() => setDropdown(null)}
                    onClick={(e) => {
                        if (dropdown && dropdown.name === "line") {
                            setDropdown(null);
                            return;
                        }
                        setDropdown({
                            location: e.currentTarget,
                            name: "line",
                        });
                        resetControls();
                    }}
                >
                    {" "}
                    <IconLine />
                </Button>
                <Menu
                    anchorEl={dropdown && dropdown.location}
                    open={Boolean(dropdown && dropdown.name === "line")}
                    onClose={() => setDropdown(null)}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                >
                    <MenuItem // regular line
                        onClick={() => {
                            resetControls();
                            setDropdown(null);
                            // isAddingLine.current = true;
                            setParentState({
                                ...parentState,
                                editingMode: "addingLine",
                                addingVariant: {
                                    dashed: false,
                                    head: null,
                                },
                            });
                        }}
                    >
                        <IconLetterI
                            style={{
                                transform: "rotate(90deg)",
                            }}
                        />
                    </MenuItem>
                    <MenuItem // flathead
                        onClick={() => {
                            resetControls();
                            setDropdown(null);
                            // isAddingLine.current = true;
                            setParentState({
                                ...parentState,
                                editingMode: "addingLine",
                                addingVariant: {
                                    dashed: false,
                                    head: "flat",
                                },
                            });
                        }}
                    >
                        <IconLetterT
                            style={{
                                transform: "rotate(90deg)",
                            }}
                        />
                    </MenuItem>
                    <MenuItem // arrow
                        onClick={() => {
                            resetControls();
                            setDropdown(null);
                            // isAddingLine.current = true;
                            setParentState({
                                ...parentState,
                                editingMode: "addingLine",
                                addingVariant: {
                                    dashed: false,
                                    head: "arrow",
                                    jagged: false,
                                },
                            });
                        }}
                    >
                        <IconArrowNarrowRight />
                    </MenuItem>
                    <MenuItem //dashed line
                        onClick={() => {
                            resetControls();
                            setDropdown(null);
                            // isAddingLine.current = true;
                            setParentState({
                                ...parentState,
                                editingMode: "addingLine",
                                addingVariant: {
                                    dashed: true,
                                    head: null,
                                },
                            });
                        }}
                    >
                        <IconLineDashed style={{}} />
                    </MenuItem>
                    <MenuItem //dashed flathead
                        onClick={() => {
                            resetControls();
                            setDropdown(null);
                            // isAddingLine.current = true;
                            setParentState({
                                ...parentState,
                                editingMode: "addingLine",
                                addingVariant: {
                                    dashed: true,
                                    head: "flat",
                                },
                            });
                        }}
                    >
                        <img
                            alt=""
                            src={DashedFlatArrow}
                            style={{ height: "24px" }}
                        />
                    </MenuItem>
                    <MenuItem // dashed arrow head
                        onClick={() => {
                            resetControls();
                            setDropdown(null);
                            // isAddingLine.current = true;
                            setParentState({
                                ...parentState,
                                editingMode: "addingLine",
                                addingVariant: {
                                    dashed: true,
                                    head: "arrow",
                                    jagged: false,
                                },
                            });
                        }}
                    >
                        <IconArrowNarrowRightDashed />
                    </MenuItem>
                    <MenuItem // jagged line
                        onClick={() => {
                            resetControls();
                            setDropdown(null);
                            // isAddingLine.current = true;
                            setParentState({
                                ...parentState,
                                editingMode: "addingLine",
                                addingVariant: {
                                    dashed: false,
                                    head: null,
                                    jagged: true,
                                },
                            });
                        }}
                    >
                        <img
                            alt=""
                            src={ZigZagLine}
                            style={{ height: "24px" }}
                        />
                    </MenuItem>
                </Menu>
            </Box>{" "}
            <Box width="64px" height="64px" borderRight="1px solid black">
                <Button
                    variant="outlined"
                    style={{
                        bgcolor: "grey",
                        width: "64px",
                        height: "64px",
                        color: "black",
                        border: "none",
                    }}
                    onClose={() => setDropdown(null)}
                    onClick={(e) => {
                        if (dropdown && dropdown.name === "shape") {
                            setDropdown(null);
                            return;
                        }
                        setDropdown({
                            location: e.currentTarget,
                            name: "shape",
                        });
                        resetControls();
                    }}
                >
                    {" "}
                    <IconCircleSquare />
                </Button>
                <Menu
                    anchorEl={dropdown && dropdown.location}
                    open={Boolean(dropdown && dropdown.name === "shape")}
                    onClose={() => setDropdown(null)}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                >
                    <MenuItem
                        onClick={() => {
                            setDropdown(null);
                            isDrawing.current = false;
                            isAddingLine.current = false;
                            setPreviewedShape({
                                shape: "circle",
                                id: uuidv4(),
                                selected: false,
                                x: 0,
                                y: 0,
                                border: true,
                                label: "",
                                fill: "empty",
                                color: "black",
                                radius: 0.625,
                            });
                            setParentState({
                                ...parentState,
                                editingMode: "addingIcon",
                            });
                        }}
                    >
                        <IconCircle />
                    </MenuItem>

                    <MenuItem
                        onClick={() => {
                            setDropdown(null);
                            isDrawing.current = false;
                            isAddingLine.current = false;
                            setPreviewedShape({
                                shape: "rect",
                                id: uuidv4(),
                                selected: false,
                                x: 0,
                                y: 0,
                                border: true,
                                label: "",
                                fill: "empty",
                                color: "black",
                                radius: 0.625,
                            });
                            setParentState({
                                ...parentState,
                                editingMode: "addingIcon",
                            });
                        }}
                    >
                        <IconSquare />
                    </MenuItem>

                    <MenuItem
                        onClick={() => {
                            setDropdown(null);
                            isDrawing.current = false;
                            isAddingLine.current = false;
                            setPreviewedShape({
                                shape: "triangle",
                                id: uuidv4(),
                                selected: false,
                                x: 0,
                                y: 0,
                                border: true,
                                label: "",
                                fill: "empty",
                                color: "black",
                                radius: 0.625,
                            });
                            setParentState({
                                ...parentState,
                                editingMode: "addingIcon",
                            });
                        }}
                    >
                        <IconTriangle />
                    </MenuItem>
                </Menu>
            </Box>
            <Box width="64px" height="64px" borderRight="1px solid black">
                <Button
                    variant="outlined"
                    style={{
                        bgcolor: "grey",
                        width: "64px",
                        height: "64px",
                        color: "black",
                        border: "none",
                    }}
                    onClose={() => setDropdown(null)}
                    onClick={(e) => {
                        if (dropdown && dropdown.name === "zone") {
                            setDropdown(null);
                            return;
                        }
                        setDropdown({
                            location: e.currentTarget,
                            name: "zone",
                        });
                        resetControls();
                    }}
                >
                    {" "}
                    <img
                        alt=""
                        src={IconRectOvalZone}
                        style={{ height: "48px" }}
                    />
                </Button>
                <Menu
                    anchorEl={dropdown && dropdown.location}
                    open={Boolean(dropdown && dropdown.name === "zone")}
                    onClose={() => setDropdown(null)}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                >
                    <Box
                        width="64px"
                        display="flex"
                        flexDirect="column"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <MenuItem
                            onClick={() => {
                                setDropdown(null);
                                isDrawing.current = false;
                                isAddingLine.current = false;
                                setParentState({
                                    ...parentState,
                                    editingMode: "addingRectZone",
                                    addingVariant: "rect",
                                });
                            }}
                        >
                            <img
                                alt=""
                                src={IconRectZone}
                                style={{ height: "48px" }}
                            />
                        </MenuItem>
                    </Box>
                    <Box
                        width="64px"
                        display="flex"
                        flexDirect="column"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <MenuItem
                            onClick={() => {
                                setDropdown(null);
                                isDrawing.current = false;
                                isAddingLine.current = false;
                                setParentState({
                                    ...parentState,
                                    editingMode: "addingOvalZone",
                                });
                            }}
                        >
                            <img
                                alt=""
                                src={IconOvalZone}
                                style={{ height: "48px" }}
                            />
                        </MenuItem>
                    </Box>
                </Menu>
            </Box>
            <Box width="64px" height="64px" borderRight="1px solid black">
                <Button
                    style={{
                        bgcolor: "grey",
                        width: "64px",
                        height: "64px",
                        color: "black",
                        border: "none",
                    }}
                    onClick={() => {
                        setDropdown(null);
                        isDrawing.current = false;
                        isAddingLine.current = false;
                        setPreviewedShape({
                            shape: "text",
                            id: uuidv4(),
                            selected: false,
                            x: 0,
                            y: 0,
                            border: false,
                            text: "Abc...",
                            fill: "empty",
                            color: "black",
                            radius: 0.625,
                        });
                        setParentState({
                            ...parentState,
                            editingMode: "addingIcon",
                        });
                    }}
                >
                    <IconLetterCase />
                </Button>
            </Box>{" "}
            <Box width="64px" height="64px" borderRight="1px solid black">
                <Button
                    style={{
                        bgcolor: "grey",
                        width: "64px",
                        height: "64px",
                        color: "black",
                        border: "none",
                    }}
                    onClick={() => {
                        setDropdown(null);
                        setUndoStack([
                            ...undoStack,
                            {
                                shapes: parentState.shapes,
                                direction: direction.current,
                                losLocation,
                                formation: parentState.formation,
                                play: parentState.play,
                                vsFormation: parentState.vsFormation,
                                vsPlay: parentState.vsPlay,
                                notes: parentState.notes,
                                positionGroup: parentState.positionGroup,
                                fileType: parentState.fileType,
                            },
                        ]);
                        setRedoStack([]);
                        setParentState({
                            ...parentState,
                            shapes: flipPlayHorizontal({
                                shapes: parentState.shapes,
                            }),
                        });
                    }}
                >
                    <IconArrowsLeftRight />
                </Button>
            </Box>{" "}
            <Box width="64px" height="64px" borderRight="1px solid black">
                <Button
                    style={{
                        bgcolor: "grey",
                        width: "64px",
                        height: "64px",
                        color: "black",
                        border: "none",
                    }}
                    onClick={() => {
                        setDropdown(null);
                        setUndoStack([
                            ...undoStack,
                            {
                                shapes: parentState.shapes,
                                direction: direction.current,
                                losLocation,
                                formation: parentState.formation,
                                play: parentState.play,
                                vsFormation: parentState.vsFormation,
                                vsPlay: parentState.vsPlay,
                                notes: parentState.notes,
                                positionGroup: parentState.positionGroup,
                                fileType: parentState.fileType,
                            },
                        ]);
                        setRedoStack([]);
                        setParentState({
                            ...parentState,
                            shapes: flipPlayVertical({
                                shapes: parentState.shapes,
                                losLocation,
                                setLosLocation,
                                direction,
                            }),
                        });
                    }}
                >
                    <IconArrowsUpDown />
                </Button>
            </Box>{" "}
        </Box>
    );
}
