import React, { useState } from "react";
import { Box, Typography, Button, Tooltip } from "@mui/material";
import Modal from "@mui/material/Modal";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import concatLabel from "../helpers/concatLabel";

export default function ConfirmSave({
    savePlay,
    setSaveState,
    playId,
    formation,
    play,
    vsFormation,
    vsPlay,
    state,
    setState,
}) {
    const { fileType, positionGroup } = state;
    const [saveType, setSaveType] = useState("new"); // new or overwrite
    const label = concatLabel({ formation, play, vsFormation, vsPlay });
    return (
        <Modal open={true} onClose={() => setSaveState("idle")}>
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    bgcolor: "background.paper",
                    border: "2px solid black",
                    width: 600,
                    height: "auto",
                    boxShadow: 24,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Box
                    width={600}
                    display="flex"
                    flexDirection={"column"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    gap={4}
                    padding="20px"
                >
                    <Box
                        overflow={"scroll"}
                        width={500}
                        display="flex"
                        flexDirection={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                    >
                        {" "}
                        <Typography variant="h5">{label} </Typography>
                    </Box>

                    <ToggleButtonGroup
                        value={positionGroup}
                        exclusive
                        onChange={(e, value) =>
                            setState({ ...state, positionGroup: value })
                        }
                    >
                        {" "}
                        <Tooltip title="Offense">
                            <ToggleButton value="Offense">
                                {" "}
                                <Typography>Offense</Typography>
                            </ToggleButton>
                        </Tooltip>
                        <ToggleButton value="Defense">
                            <Tooltip title="Defense">
                                {" "}
                                <Typography>Defense</Typography>
                            </Tooltip>
                        </ToggleButton>
                        <ToggleButton value="Special Teams">
                            <Tooltip title="Special Teams">
                                {" "}
                                <Typography>Special Teams</Typography>
                            </Tooltip>
                        </ToggleButton>
                    </ToggleButtonGroup>
                    <ToggleButtonGroup
                        value={fileType}
                        exclusive
                        onChange={(e, value) =>
                            setState({ ...state, fileType: value })
                        }
                    >
                        {" "}
                        <Tooltip title="Formation">
                            <ToggleButton value="Formation">
                                {" "}
                                <Typography>Formation</Typography>
                            </ToggleButton>
                        </Tooltip>
                        <ToggleButton value="Play">
                            <Tooltip title="Play">
                                {" "}
                                <Typography>Play</Typography>
                            </Tooltip>
                        </ToggleButton>
                        <ToggleButton value="Opponent Formation">
                            <Tooltip title="Opponent Formation">
                                {" "}
                                <Typography>Opp. Form.</Typography>
                            </Tooltip>
                        </ToggleButton>{" "}
                        <ToggleButton value="Opponent Play">
                            <Tooltip title="Opponent Play">
                                {" "}
                                <Typography>Opp. Play</Typography>
                            </Tooltip>
                        </ToggleButton>
                    </ToggleButtonGroup>
                    <ToggleButtonGroup
                        value={saveType}
                        exclusive
                        onChange={(e, value) => setSaveType(value)}
                    >
                        <ToggleButton value="new">
                            <Tooltip title="Save as New">
                                {" "}
                                <Typography>Save as New</Typography>
                            </Tooltip>
                        </ToggleButton>
                        {playId !== "new" && (
                            <ToggleButton value="overwrite">
                                <Tooltip title="Overwrite">
                                    {" "}
                                    <Typography>Overwrite</Typography>
                                </Tooltip>
                            </ToggleButton>
                        )}
                    </ToggleButtonGroup>
                    <Box
                        display="flex"
                        flexDirection={"row"}
                        gap={6}
                        marginTop="20px"
                    >
                        <Button
                            color="error"
                            onClick={() => setSaveState("idle")}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() =>
                                savePlay({
                                    saveType,
                                })
                            }
                        >
                            Confirm
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
}
