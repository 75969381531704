const handleMouseMove = ({
    e,
    state,
    setState,
    isDrawing,
    isAddingLine,
    hoveredId,
    setHoveredId,
    editingMode,
    fieldDimensions,
    lasooSelection,
    setLasooSelection,
    redoStack,
    setRedoStack,
    previewedShape,
    setPreviewedShape,
    anchoredPreviewedLine,
    isAddingZone,
}) => {
    if (!state.editing) {
        return;
    }

    const stage = e.target.getStage();
    const mousePos = stage.getPointerPosition();
    const stagePosition = stage.position();
    const shape = stage.getIntersection(stage.getPointerPosition());
    if (shape && shape.id() !== hoveredId) {
        setHoveredId(shape.id());
    } else if (!shape && hoveredId) {
        setHoveredId(null);
    }
    if (state.editingMode === "addingIcon") {
        const x = (mousePos.x - stagePosition.x) * (65 / fieldDimensions.width);
        const y =
            (mousePos.y - stagePosition.y) * (150 / fieldDimensions.height);
        setPreviewedShape({
            ...previewedShape,
            x,
            y,
        });
    } else if (state.editingMode === "addingLine") {
        if (isDrawing.current) {
            /// free hand drawing
            let lastLine = state.shapes[state.shapes.length - 1];
            lastLine.points = lastLine.points.concat([
                (mousePos.x - stagePosition.x) * (65 / fieldDimensions.width),
                (mousePos.y - stagePosition.y) * (150 / fieldDimensions.height),
            ]);
            state.shapes.splice(state.shapes.length - 1, 1, lastLine);
            setState({
                ...state,
                shapes: state.shapes,
            });
            setPreviewedShape({
                ...previewedShape,
                points: previewedShape.points
                    .slice(0, -4)
                    .concat([
                        (mousePos.x - stagePosition.x) *
                            (65 / fieldDimensions.width),
                        (mousePos.y - stagePosition.y) *
                            (150 / fieldDimensions.height),
                        (mousePos.x - stagePosition.x) *
                            (65 / fieldDimensions.width),
                        (mousePos.y - stagePosition.y) *
                            (150 / fieldDimensions.height),
                    ]),
            });
        } else if (anchoredPreviewedLine.current) {
            setPreviewedShape({
                ...previewedShape,
                points: previewedShape.points
                    .slice(0, -2)
                    .concat([
                        (mousePos.x - stagePosition.x) *
                            (65 / fieldDimensions.width),
                        (mousePos.y - stagePosition.y) *
                            (150 / fieldDimensions.height),
                    ]),
            });
        }
    } else if (
        state.editingMode === "selecting" &&
        lasooSelection.isSelecting
    ) {
        const x = mousePos.x - stagePosition.x;
        const y = mousePos.y - stagePosition.y;
        const newWidth = x - lasooSelection.startPos.x;
        const newHeight = y - lasooSelection.startPos.y;
        setLasooSelection({
            ...lasooSelection,
            selectionRect: {
                x: newWidth < 0 ? x : lasooSelection.startPos.x,
                y: newHeight < 0 ? y : lasooSelection.startPos.y,
                width: Math.abs(newWidth),
                height: Math.abs(newHeight),
            },
        });
    } else if (
        (state.editingMode === "addingRectZone" ||
            state.editingMode === "addingOvalZone") &&
        isAddingZone.current
    ) {
        let x = (mousePos.x - stagePosition.x) * (65 / fieldDimensions.width);
        let y = (mousePos.y - stagePosition.y) * (150 / fieldDimensions.height);
        const newWidth = x - previewedShape.originalX;
        const newHeight = y - previewedShape.originalY;

        if (state.editingMode === "addingOvalZone") {
            x = newWidth < 0 - newWidth / 2 ? x : previewedShape.originalX;
            y = newHeight < 0 - newHeight / 2 ? y : previewedShape.originalY;
        } else if (state.editingMode === "addingRectZone") {
            x = newWidth < 0 ? x : previewedShape.originalX;
            y = newHeight < 0 ? y : previewedShape.originalY;
        }

        setPreviewedShape({
            ...previewedShape,
            x,
            y,
            width: Math.abs(newWidth),
            height: Math.abs(newHeight),
        });
    }
    return;
};

export default handleMouseMove;
