import { v4 as uuidv4 } from "uuid";

const handleMouseDown = ({
    e,
    state,
    setState,
    isDrawing,
    isAddingLine,
    editingMode,
    fieldDimensions,
    lasooSelection,
    setLasooSelection,
    undoStack,
    setUndoStack,
    redoStack,
    setRedoStack,
    previewedShape,
    setPreviewedShape,
    anchoredPreviewedLine,
    direction,
    losLocation,
    hoveredId,
    isAddingZone,
}) => {
    if (!state.editing) return;
    if (e.evt.button === 2) return; // Ignore right clicks

    const stage = e.target.getStage();
    const mousePos = stage.getPointerPosition();
    const stagePosition = stage.position();
    if (state.editingMode === "addingIcon") {
        const lastShapes = state.shapes;
        setUndoStack([
            ...undoStack,
            {
                shapes: lastShapes,
                direction: direction.current,
                losLocation,
                formation: state.formation,
                play: state.play,
                vsFormation: state.vsFormation,
                vsPlay: state.vsPlay,
                notes: state.notes,
                positionGroup: state.positionGroup,
                fileType: state.fileType,
            },
        ]);
        setRedoStack([]);
        setState({
            ...state,
            shapes: [...state.shapes, { ...previewedShape, id: uuidv4() }],
        });
    } else if (state.editingMode === "addingLine") {
        if (hoveredId) {
            const hoveredShape = state.shapes.find(
                (shape) => shape.id === hoveredId
            );
            if (hoveredShape) {
                isAddingLine.current = false;
                anchoredPreviewedLine.current = false;
                setPreviewedShape(null);
            }
        }

        isDrawing.current = true;

        if (isAddingLine.current === true) {
            setUndoStack([
                ...undoStack,
                {
                    shapes: state.shapes,
                    direction: direction.current,
                    losLocation,
                    formation: state.formation,
                    play: state.play,
                    vsFormation: state.vsFormation,
                    vsPlay: state.vsPlay,
                    notes: state.notes,
                    positionGroup: state.positionGroup,
                    fileType: state.fileType,
                },
            ]);
            setRedoStack([]);
            setState({
                ...state,
                shapes: [
                    ...state.shapes.slice(0, -1),
                    {
                        ...state.shapes[state.shapes.length - 1],
                        points: [
                            ...state.shapes[state.shapes.length - 1].points,
                            (mousePos.x - stagePosition.x) *
                                (65 / fieldDimensions.width),
                            (mousePos.y - stagePosition.y) *
                                (150 / fieldDimensions.height),
                        ],
                    },
                ],
            });

            setPreviewedShape({
                ...previewedShape,
                points: previewedShape.points
                    .slice(0, -4)
                    .concat([
                        (mousePos.x - stagePosition.x) *
                            (65 / fieldDimensions.width),
                        (mousePos.y - stagePosition.y) *
                            (150 / fieldDimensions.height),
                        (mousePos.x - stagePosition.x) *
                            (65 / fieldDimensions.width),
                        (mousePos.y - stagePosition.y) *
                            (150 / fieldDimensions.height),
                    ]),
            });
        } else {
            const lastShapes = state.shapes;
            isAddingLine.current = true;
            setUndoStack([
                ...undoStack,
                {
                    shapes: lastShapes,
                    direction: direction.current,
                    losLocation,
                    formation: state.formation,
                    play: state.play,
                    vsFormation: state.vsFormation,
                    vsPlay: state.vsPlay,
                    notes: state.notes,
                    positionGroup: state.positionGroup,
                    fileType: state.fileType,
                },
            ]);
            setRedoStack([]);
            setState({
                ...state,

                shapes: [
                    ...state.shapes,
                    {
                        shape: "line",
                        head: state.addingVariant.head,
                        dashed: state.addingVariant.dashed,
                        jagged: state.addingVariant.jagged,
                        id: uuidv4(),
                        selected: false,
                        color: "black",
                        radius: 0.25,
                        points: [
                            (mousePos.x - stagePosition.x) *
                                (65 / fieldDimensions.width),
                            (mousePos.y - stagePosition.y) *
                                (150 / fieldDimensions.height),
                        ],
                    },
                ],
            });
            setPreviewedShape({
                shape: "line",
                head: state.addingVariant.head,
                dashed: state.addingVariant.dashed,
                jagged: state.addingVariant.jagged,
                id: uuidv4(),
                selected: false,
                color: "black",
                radius: 0.25,
                points: [
                    (mousePos.x - stagePosition.x) *
                        (65 / fieldDimensions.width),
                    (mousePos.y - stagePosition.y) *
                        (150 / fieldDimensions.height),
                    (mousePos.x - stagePosition.x) *
                        (65 / fieldDimensions.width),
                    (mousePos.y - stagePosition.y) *
                        (150 / fieldDimensions.height),
                ],
            });
            anchoredPreviewedLine.current = true;
        }
    } else if (state.editingMode === "selecting") {
        const x = mousePos.x - stagePosition.x;
        const y = mousePos.y - stagePosition.y;
        setLasooSelection({
            isSelecting: true,
            startPos: { x, y },
            selectionRect: { x, y, width: 0, height: 0 },
        });
    } else if (
        state.editingMode === "addingOvalZone" ||
        state.editingMode === "addingRectZone"
    ) {
        isAddingZone.current = true;
        const x = (mousePos.x - stagePosition.x) * (65 / fieldDimensions.width);
        const y =
            (mousePos.y - stagePosition.y) * (150 / fieldDimensions.height);


        setPreviewedShape({
            shape:
                state.editingMode === "addingOvalZone"
                    ? "ovalZone"
                    : "rectZone",
            id: uuidv4(),
            selected: false,
            x,
            y,
            originalX: x,
            originalY: y,
            border: true,
            color: "grey",
            width: 0,
            height: 0,
        });
    }
};
export default handleMouseDown;
