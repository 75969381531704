import { createTheme } from "@mui/material/styles";

// A custom theme for this app
const theme = createTheme({
    cssVariables: true,
    typography: {
        fontFamily: ["Roboto", "Arial", "sans-serif"].join(","),
    },
    palette: {
        primary: {
            main: "#06d6a0",
            contrastText: "#000",
        },
        secondary: {
            main: "#dda15e",
            contrastText: "#fefae0",
        },
        warning: {
            main: "#f3722c",
            contrastText: "#fefae0",
        },
        background: {
            default: "#FAFAFA",
            paper: "#FAFAFA",
        },
    },
});

export default theme;
