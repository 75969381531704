import { TextField, Box, Skeleton, Autocomplete } from "@mui/material";
import React, { useState } from "react";
import Field from "./Field";
import Grid from "@mui/material/Grid2";
import { UserContext } from "../../UserContext";
import { get, post } from "aws-amplify/api";
import { fetchAuthSession } from "aws-amplify/auth";
import { useParams } from "react-router-dom";
import FileExplorer from "./FileExplorer/index.js";
import { useNavigate } from "react-router-dom";
import useResizeEffect from "./useResizeEffect.js";
import ConfirmSave from "./ConfirmSave";
import MenuBar from "./MenuBar";

const PlayDesigner = () => {
    const [state, setState] = useState({
        id: "",
        editing: true,
        formation: "",
        play: "",
        vsFormation: "",
        vsPlay: "",
        positionGroup: "Offense",
        fileType: "Play",
        notes: "",
        shapes: [],
        editingMode: "selecting", // selecting, drawing, addingIcon
        cursor: "default",
    });
    const navigate = useNavigate();
    const [undoStack, setUndoStack] = useState([]);
    const [redoStack, setRedoStack] = useState([]);
    const isAddingLine = React.useRef(false);
    const [previewedShape, setPreviewedShape] = useState(null);
    const isDrawing = React.useRef(false);
    const anchoredPreviewedLine = React.useRef(false);
    const [contextMenu, setContextMenu] = useState(null);
    const [clipboard, setClipboard] = useState([]);
    const [losLocation, setLosLocation] = useState(55);
    const direction = React.useRef("north");
    const { user } = React.useContext(UserContext);
    const { playId } = useParams();
    const [fetchState, setFetchState] = React.useState("idle");
    const [saveState, setSaveState] = React.useState("idle");
    const [fieldDimensions, setFieldDimensions] = useState({
        width: 0,
        height: 0,
    });
    const [stageDimensions, setStageDimensions] = useState({
        width: 0,
        height: 0,
    });
    useResizeEffect(setStageDimensions, setFieldDimensions);
    const [searchedFiles, setSearchedFiles] = useState([]);

    async function savePlay({ saveType }) {
        if (saveState === "saving") return;

        if (saveType === "new") {
            const play = {
                tenantId: user.activeOrganization.id,
                formation: state.formation,
                play: state.play,
                vsFormation: state.vsFormation,
                vsPlay: state.vsPlay,
                shapes: state.shapes.map((shape) => {
                    const { selected, ...rest } = shape;
                    return { ...rest };
                }),
                losLocation: losLocation,
                direction: direction.current,
                positionGroup: state.positionGroup,
                fileType: state.fileType,
            };
            setSaveState("saving");

            const restOperation = post({
                apiName: "playbook",
                path: "/create",
                options: {
                    body: play,
                    headers: {
                        Authorization: (
                            await fetchAuthSession()
                        ).tokens?.idToken?.toString(),
                    },
                },
            });
            const response = await restOperation.response;
            const json = await response.body.json();
            setSaveState("saved");

            navigate(`/play-designer/${json.playbookId}`);
        } else {
            if (undoStack.length === 0) return;
            const firstStack = undoStack[0];

            const body = {};
            if (state.notes !== firstStack.notes) {
                body.notes = state.notes;
            }
            if (state.formation !== firstStack.formation) {
                body.formation = state.formation;
            }
            if (state.play !== firstStack.play) {
                body.play = state.play;
            }
            if (state.vsFormation !== firstStack.vsFormation) {
                body.vsFormation = state.vsFormation;
            }
            if (state.vsPlay !== firstStack.vsPlay) {
                body.vsPlay = state.vsPlay;
            }
            body.shapes = state.shapes.map((shape) => {
                const { selected, ...rest } = shape;
                return { ...rest };
            });
            if (losLocation !== firstStack.losLocation) {
                body.losLocation = losLocation;
            }
            if (direction.current !== firstStack.direction) {
                body.direction = direction.current;
            }
            if (state.positionGroup !== firstStack.positionGroup) {
                body.positionGroup = state.positionGroup;
            }
            if (state.fileType !== firstStack.fileType) {
                body.fileType = state.fileType;
            }

            if (Object.keys(body).length === 0) return;
            setSaveState("saving");

            const restOperation = post({
                apiName: "playbook",
                path: "/update",
                options: {
                    body,
                    queryParams: {
                        playbookId: playId,
                        tenantId: user.activeOrganization.id,
                    },
                    headers: {
                        Authorization: (
                            await fetchAuthSession()
                        ).tokens?.idToken?.toString(),
                    },
                },
            });
            const response = await restOperation.response;
            await response.body.json();
            setSaveState("saved");
        }
    }

    async function searchFiles({ fileType, search }) {
        if (!user.activeOrganization) return null;

        const queryParams = {
            tenantId: user.activeOrganization.id,
        };
        if (fileType) {
            queryParams.fileType = fileType;
        }
        if (search.formation) {
            queryParams.formation = search.formation;
        }

        const restOperation = get({
            apiName: "playbook",
            path: "/list",
            options: {
                queryParams,
                headers: {
                    Authorization: (
                        await fetchAuthSession()
                    ).tokens?.idToken?.toString(),
                },
            },
        });
        const response = await restOperation.response;
        const json = await response.body.json();
        setSearchedFiles(json);
    }

    async function fetchPlay() {
        if (!user.activeOrganization) return null;

        setFetchState("fetching");
        const restOperation = get({
            apiName: "playbook",
            path: "/get",
            options: {
                queryParams: {
                    playbookId: playId,
                    tenantId: user.activeOrganization.id,
                },
                headers: {
                    Authorization: (
                        await fetchAuthSession()
                    ).tokens?.idToken?.toString(),
                },
            },
        });
        const response = await restOperation.response;
        const json = await response.body.json();
        direction.current = json.direction;
        setState({
            ...state,
            id: json.playbookId,
            formation: json.formation,
            play: json.play,
            vsFormation: json.vsFormation,
            vsPlay: json.vsPlay,
            shapes: json.shapes.map((shape) => {
                const { selected, ...rest } = shape;
                return { ...rest };
            }),
            notes: json.notes,
            positionGroup: json.positionGroup,
            fileType: json.fileType,
        });
        setLosLocation(json.losLocation);
        setFetchState("fetched");
    }

    async function loadFormation({ playbookId }) {
        if (!user.activeOrganization) return null;

        setFetchState("fetching");
        const restOperation = get({
            apiName: "playbook",
            path: "/get",
            options: {
                queryParams: {
                    playbookId,
                    tenantId: user.activeOrganization.id,
                },
                headers: {
                    Authorization: (
                        await fetchAuthSession()
                    ).tokens?.idToken?.toString(),
                },
            },
        });
        const response = await restOperation.response;
        const json = await response.body.json();
        setState({
            ...state,
            formation: json.formation,
            shapes: json.shapes.map((shape) => {
                const { selected, ...rest } = shape;
                return { ...rest };
            }),
        });
        setLosLocation(json.losLocation);
        setFetchState("fetched");
    }

    if (fetchState === "idle" && playId !== "new") {
        fetchPlay(playId);
    }

    if (playId !== "new" && playId !== state.id && fetchState === "fetched") {
        setUndoStack([]);
        setRedoStack([]);
        fetchPlay(playId);
    }

    if (playId === "new" && state.id !== "") {
        setState({
            id: "",
            editing: true,
            formation: "",
            play: "",
            vsFormation: "",
            vsPlay: "",
            shapes: [],
            editingMode: "selecting", // selecting, drawing, addingIcon
            cursor: "default",
        });
    }

    const isLoading = fetchState === "fetching" || saveState === "saving";

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            paddingTop="35px"
            gap="1vh"
            paddingBottom="250px"
        >
            {saveState === "confirming" && (
                <ConfirmSave
                    savePlay={savePlay}
                    setSaveState={setSaveState}
                    playId={playId}
                    formation={state.formation}
                    play={state.play}
                    vsFormation={state.vsFormation}
                    vsPlay={state.vsPlay}
                    state={state}
                    setState={setState}
                />
            )}
            {isLoading ? (
                <Box marginLeft="-64px">
                    <Skeleton
                        variant="rectangular"
                        width={stageDimensions.width + 64}
                        height={64}
                    />
                </Box> // Name/Formation Menu
            ) : (
                <Box
                    width={{
                        xs: "90vw",
                        sm: "600px",
                        md: "900px",
                        lg: "1200px",
                        xl: "1500px",
                    }}
                >
                    <Grid
                        container
                        columnSpacing={2}
                        display="flex"
                        flexDirection="row"
                        width={stageDimensions.width + 64}
                        marginLeft="-64px"
                    >
                        <Grid size={3}>
                            <Autocomplete
                                freeSolo
                                disableClearable
                                options={searchedFiles}
                                getOptionLabel={(option) => option.formation}
                                inputValue={state.formation}
                                onChange={(event, newValue) => {
                                    loadFormation({
                                        playbookId: newValue.playbookId,
                                    });
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Formation"
                                        slotProps={{
                                            input: {
                                                ...params.InputProps,
                                                type: "search",
                                            },
                                        }}
                                        onChange={(e) => {
                                            searchFiles({
                                                fileType: "Formation",
                                                search: {
                                                    formation: e.target.value,
                                                },
                                            });
                                            setRedoStack([]);
                                            setUndoStack([
                                                ...undoStack,
                                                {
                                                    shapes: state.shapes,
                                                    direction:
                                                        direction.current,
                                                    losLocation,
                                                    formation: state.formation,
                                                    play: state.play,
                                                    vsFormation:
                                                        state.vsFormation,
                                                    vsPlay: state.vsPlay,
                                                    notes: state.notes,
                                                    positionGroup:
                                                        state.positionGroup,
                                                    fileType: state.fileType,
                                                },
                                            ]);
                                            setState({
                                                ...state,
                                                formation: e.target.value,
                                            });
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid size={3}>
                            <TextField
                                label="Play"
                                variant="outlined"
                                value={state.play}
                                sx={{ width: "100%" }}
                                onChange={(e) => {
                                    setRedoStack([]);
                                    setUndoStack([
                                        ...undoStack,
                                        {
                                            shapes: state.shapes,
                                            direction: direction.current,
                                            losLocation,
                                            formation: state.formation,
                                            play: state.play,
                                            vsFormation: state.vsFormation,
                                            vsPlay: state.vsPlay,
                                            notes: state.notes,
                                            positionGroup: state.positionGroup,
                                            fileType: state.fileType,
                                        },
                                    ]);
                                    setState({
                                        ...state,
                                        play: e.target.value,
                                    });
                                }}
                            />
                        </Grid>
                        <Grid size={3}>
                            <TextField
                                label="Opponent Formation"
                                variant="outlined"
                                value={state.vsFormation}
                                sx={{ width: "100%" }}
                                onChange={(e) => {
                                    setRedoStack([]);
                                    setUndoStack([
                                        ...undoStack,
                                        {
                                            shapes: state.shapes,
                                            direction: direction.current,
                                            losLocation,
                                            formation: state.formation,
                                            play: state.play,
                                            vsFormation: state.vsFormation,
                                            vsPlay: state.vsPlay,
                                            notes: state.notes,
                                            positionGroup: state.positionGroup,
                                            fileType: state.fileType,
                                        },
                                    ]);
                                    setState({
                                        ...state,
                                        vsFormation: e.target.value,
                                    });
                                }}
                            />
                        </Grid>
                        <Grid size={3}>
                            <TextField
                                label="Opponent Play"
                                variant="outlined"
                                value={state.vsPlay}
                                sx={{ width: "100%" }}
                                onChange={(e) => {
                                    setRedoStack([]);
                                    setUndoStack([
                                        ...undoStack,
                                        {
                                            shapes: state.shapes,
                                            direction: direction.current,
                                            losLocation,
                                            formation: state.formation,
                                            play: state.play,
                                            vsFormation: state.vsFormation,
                                            vsPlay: state.vsPlay,
                                            notes: state.notes,
                                            positionGroup: state.positionGroup,
                                            fileType: state.fileType,
                                        },
                                    ]);
                                    setState({
                                        ...state,
                                        vsPlay: e.target.value,
                                    });
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>
            )}
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                paddingBottom="250px"
            >
                {isLoading ? (
                    <Box marginLeft="-64px">
                        <Skeleton
                            variant="rectangular"
                            width={stageDimensions.width + 64}
                            height={64}
                        />
                    </Box>
                ) : (
                    <MenuBar
                        stageDimensions={stageDimensions}
                        parentState={state}
                        setParentState={setState}
                        undoStack={undoStack}
                        setUndoStack={setUndoStack}
                        redoStack={redoStack}
                        setRedoStack={setRedoStack}
                        isAddingLine={isAddingLine}
                        previewedShape={previewedShape}
                        setPreviewedShape={setPreviewedShape}
                        isDrawing={isDrawing}
                        anchoredPreviewedLine={anchoredPreviewedLine}
                        losLocation={losLocation}
                        setLosLocation={setLosLocation}
                        direction={direction}
                        saveState={saveState}
                        setSaveState={setSaveState}
                        savePlay={savePlay}
                    />
                )}
                {isLoading ? (
                    <Box marginLeft="-64px">
                        <Skeleton
                            variant="rectangular"
                            width={stageDimensions.width + 64}
                            height={stageDimensions.height}
                        />
                    </Box>
                ) : (
                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                        alignItems="center"
                        height={stageDimensions.height}
                        width={stageDimensions.width}
                        position="relative" // Ensure the parent box is positioned relative
                    >
                        {" "}
                        <FileExplorer
                            stageDimensions={stageDimensions}
                            state={state}
                            playId={playId}
                        />
                        <Field
                            editingMode={state.editingMode}
                            state={state}
                            setState={setState}
                            isAddingLine={isAddingLine}
                            undoStack={undoStack}
                            setUndoStack={setUndoStack}
                            redoStack={redoStack}
                            setRedoStack={setRedoStack}
                            previewedShape={previewedShape}
                            setPreviewedShape={setPreviewedShape}
                            isDrawing={isDrawing}
                            anchoredPreviewedLine={anchoredPreviewedLine}
                            contextMenu={contextMenu}
                            setContextMenu={setContextMenu}
                            clipboard={clipboard}
                            setClipboard={setClipboard}
                            losLocation={losLocation}
                            setLosLocation={setLosLocation}
                            direction={direction}
                            playId={playId}
                            stageDimensions={stageDimensions}
                            fieldDimensions={fieldDimensions}
                            fetchState={fetchState}
                            setFetchState={setFetchState}
                        />
                    </Box>
                )}
                <Box paddingTop="10px" marginLeft="-64px">
                    {isLoading ? (
                        <Skeleton
                            variant="rectangular"
                            width={stageDimensions.width + 64}
                            height="60px"
                        />
                    ) : (
                        <TextField
                            label="Notes"
                            value={state.notes}
                            onChange={(e) => {
                                setRedoStack([]);
                                setUndoStack([
                                    ...undoStack,
                                    {
                                        shapes: state.shapes,
                                        direction: direction.current,
                                        losLocation,
                                        formation: state.formation,
                                        play: state.play,
                                        vsFormation: state.vsFormation,
                                        vsPlay: state.vsPlay,
                                        notes: state.notes,
                                        positionGroup: state.positionGroup,
                                        fileType: state.fileType,
                                    },
                                ]);
                                setState({ ...state, notes: e.target.value });
                            }}
                            sx={{
                                width: stageDimensions.width + 64,
                            }}
                            variant="outlined"
                            multiline
                        />
                    )}{" "}
                </Box>
            </Box>
        </Box>
    );
};
export default PlayDesigner;
