function flipPlayHorizontal({ shapes }) {
    const newShapes = shapes.map((shape) => {
        if (shape.shape === "rect") {
            return {
                ...shape,
                x: 65 - shape.x - shape.radius * 2,
            };
        } // lines
        else if (shape.shape === "circle") {
            return {
                ...shape,
                x: 65 - shape.x,
            };
        } else if (shape.shape === "triangle") {
            return {
                ...shape,
                x: 65 - shape.x,
            };
        } else if (shape.shape === "line") {
            return {
                ...shape,
                points: shape.points.map((p, i) => {
                    if (i % 2 === 0) {
                        return 65 - p;
                    }
                    return p;
                }),
            };
        }
        return shape;
    });
    return newShapes;
}

function flipPlayVertical({ shapes, losLocation, setLosLocation, direction }) {
    const scaledViewableHeight = 65 * (9 / 16);
    // scaledViewableHeight = 36.5625
    // midway point = 18.28125
    // if los is at 55 (35 yard line) headed north
    // and we flip the play vertically
    // los should be at 38.5 (18.5 yard line) headed south

    if (direction.current === "north") {
        direction.current = "south";
        const viewportBottom = losLocation + 10;
        const viewportTop = viewportBottom - scaledViewableHeight;
        const newLosLocation = viewportTop + (viewportBottom - losLocation);
        setLosLocation(newLosLocation);
        return shapes.map((shape) => {
            if (shape.shape === "rect") {
                return {
                    ...shape,
                    x: 65 - shape.x - shape.radius * 2,
                    y:
                        viewportTop +
                        (viewportBottom - shape.y - shape.radius * 2),
                };
            } else if (shape.shape === "circle") {
                return {
                    ...shape,
                    x: 65 - shape.x,
                    y: viewportTop + (viewportBottom - shape.y),
                };
            } else if (shape.shape === "triangle") {
                return {
                    ...shape,
                    x: 65 - shape.x,
                    y:
                        viewportTop +
                        (viewportBottom - shape.y + shape.radius / 3),
                };
            } else if (shape.shape === "line") {
                return {
                    ...shape,
                    points: shape.points.map((p, i) => {
                        if (i % 2 === 1) {
                            return (
                                viewportTop +
                                (viewportBottom - p + shape.radius)
                            );
                        } else if (i % 2 === 0) {
                            return 65 - p;
                        }
                        return p;
                    }),
                };
            }
            return shape;
        });
    } else if (direction.current === "south") {
        direction.current = "north";
        const viewportBottom = losLocation + 26.5625;
        const viewportTop = viewportBottom - scaledViewableHeight;
        const newLosLocation = viewportTop + (viewportBottom - losLocation);
        setLosLocation(newLosLocation);
        return shapes.map((shape) => {
            if (shape.shape === "rect") {
                return {
                    ...shape,
                    x: 65 - shape.x - shape.radius * 2,
                    y:
                        viewportTop +
                        (viewportBottom - shape.y - shape.radius * 2),
                };
            } else if (shape.shape === "circle") {
                return {
                    ...shape,
                    x: 65 - shape.x,
                    y: viewportTop + (viewportBottom - shape.y),
                };
            } else if (shape.shape === "triangle") {
                return {
                    ...shape,
                    x: 65 - shape.x,
                    y: viewportTop + (viewportBottom - shape.y),
                };
            } else if (shape.shape === "line") {
                return {
                    ...shape,
                    points: shape.points.map((p, i) => {
                        if (i % 2 === 1) {
                            return (
                                viewportTop +
                                (viewportBottom - p + shape.radius * 1)
                            );
                        } else if (i % 2 === 0) {
                            return 65 - p;
                        }
                        return p;
                    }),
                };
            }
            return shape;
        });
    }
}

export { flipPlayHorizontal, flipPlayVertical };
