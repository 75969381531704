import React from "react";
import {
    Box,
    Button,
    Grid2 as Grid,
    Typography,
    TextField,
    Chip,
    CircularProgress,
} from "@mui/material";
import { IconRefresh, IconFolder } from "@tabler/icons-react";
import { SimpleTreeView, TreeItem2 } from "@mui/x-tree-view";
import { UserContext } from "../../../UserContext";
import { get, post } from "aws-amplify/api";
import { fetchAuthSession } from "aws-amplify/auth";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import { IconTrash } from "@tabler/icons-react";
import concatLabel from "../helpers/concatLabel";
import Skeleton from "@mui/material/Skeleton";

export default function FileExplorer({
    stageDimensions,
    state,
    playId,
    setUndoStack,
    setRedoStack,
}) {
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();

    const { user } = React.useContext(UserContext);
    const [files, setFiles] = React.useState([]);
    const [fetchState, setFetchState] = React.useState("idle");
    const [deletingIds, setDeletingIds] = React.useState([]);

    async function fetchFiles() {
        if (!user.activeOrganization) return null;

        setFetchState("fetching");
        const restOperation = get({
            apiName: "playbook",
            path: "/list",
            options: {
                queryParams: {
                    tenantId: user.activeOrganization.id,
                },
                headers: {
                    Authorization: (
                        await fetchAuthSession()
                    ).tokens?.idToken?.toString(),
                },
            },
        });
        const response = await restOperation.response;
        const json = await response.body.json();
        setFiles(json);
        setFetchState("fetched");
    }

    async function deleteFile({ deleteId }) {
        if (!user.activeOrganization) return null;
        setDeletingIds([...deletingIds, deleteId]);
        const restOperation = post({
            apiName: "playbook",
            path: "/delete",

            options: {
                queryParams: {
                    tenantId: user.activeOrganization.id,
                    playbookId: deleteId,
                },
                headers: {
                    Authorization: (
                        await fetchAuthSession()
                    ).tokens?.idToken?.toString(),
                },
            },
        });
        const response = await restOperation.response;
        await response.body.json();
        setFiles(files.filter((file) => file.playbookId !== deleteId));
        if (deleteId === playId) {
            navigate("/play-designer/new");
        }
        setDeletingIds(deletingIds.filter((id) => id !== deleteId));
    }

    if (fetchState === "idle") {
        fetchFiles();
    }

    if (fetchState === "idle" && playId !== "new") {
        fetchFiles();
    }

    if (playId !== "new" && playId !== state.id && fetchState === "fetched") {
        fetchFiles();
    }

    return (
        <Box
            position="absolute"
            top="0"
            left="0"
            zIndex={1200} // Ensure it sits on top of the field but below the AppBar
            width={open ? stageDimensions.width * 0.75 : 64}
            maxWidth={stageDimensions.width * 0.95}
            height="100%"
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="left"
            bgcolor="#93ebd4"
            overflow={open ? "visible" : "hidden"} // Allow overflow when open
            marginLeft={"-64px"}
            border="1px solid black"
        >
            <Box display="flex" flexDirection="row">
                <Box display="flex" flexDirection="column" overflow="hidden">
                    <Box
                        width="64px"
                        borderBottom="1px solid black"
                        borderRight={"none"}
                        overflow="hidden"
                    >
                        <Button
                            onClick={() => setOpen(!open)}
                            variant="outlined"
                            style={{
                                bgcolor: "grey",
                                width: "64px",
                                height: "64px",
                                color: "black",
                                borderWidth: "0px",
                            }}
                        >
                            <IconFolder />
                        </Button>
                    </Box>{" "}
                </Box>
                {open && (
                    <Box
                        width={stageDimensions.width * 0.75 - 64}
                        height={stageDimensions.height}
                        overflow="scroll"
                        bgcolor="#FAFAFA"
                        borderBottom="1px solid black"
                        borderLeft="1px solid black"
                        display="flex"
                        flexDirection="column"
                    >
                        {" "}
                        <Grid
                            container
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            justifyContent={"center"}
                            paddingY="5px"
                            height="80px"
                        >
                            <Grid
                                size={11}
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                justifyContent={"center"}
                                paddingLeft="15px"
                            >
                                {fetchState === "fetched" ? (
                                    <TextField
                                        label="Search"
                                        variant="outlined"
                                        style={{ width: "100%" }}
                                    />
                                ) : (
                                    <Skeleton width="100%" height="64px" />
                                )}
                            </Grid>
                            <Grid
                                size={1}
                                height="100%"
                                display="flex"
                                flexDirection={"column"}
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Button color="black" height="100%">
                                    {fetchState === "fetching" ? (
                                        <CircularProgress />
                                    ) : (
                                        <IconRefresh
                                            onClick={() => {
                                                fetchFiles();
                                            }}
                                            width="32px"
                                            height="32px"
                                        />
                                    )}
                                </Button>
                            </Grid>
                        </Grid>
                        <Box>
                            <SimpleTreeView>
                                {fetchState !== "fetched" &&
                                    Array.from({ length: 10 }).map((_, i) => (
                                        <Grid
                                            container
                                            marginLeft="15px"
                                            width="100%"
                                            gap={2}
                                        >
                                            <Grid size={11}>
                                                <Skeleton
                                                    key={i}
                                                    width="100%"
                                                    height="40px"
                                                />
                                            </Grid>
                                            <Grid size={0.5}>
                                                <Skeleton
                                                    key={-i}
                                                    width="100%"
                                                    height="40px"
                                                />
                                            </Grid>
                                        </Grid>
                                    ))}
                                {fetchState === "fetched" &&
                                    files.map((file) => {
                                        const label = concatLabel({
                                            formation: file.formation,
                                            play: file.play,
                                            vsFormation: file.vsFormation,
                                            vsPlay: file.vsPlay,
                                        });
                                        return (
                                            file.playbookId && (
                                                <Grid
                                                    container
                                                    paddingLeft="5px"
                                                >
                                                    <Grid
                                                        size={2.5}
                                                        display="flex"
                                                        flexDirection="row"
                                                        justifyContent="left"
                                                        alignItems="center"
                                                        columnGap={1}
                                                    >
                                                        <Chip
                                                            label={
                                                                file.positionGroup
                                                            }
                                                            style={{
                                                                backgroundColor:
                                                                    file.positionGroup ===
                                                                    "Offense"
                                                                        ? "#B39DDB"
                                                                        : file.positionGroup ===
                                                                          "Defense"
                                                                        ? "#FFE082"
                                                                        : "#90CAF9",
                                                            }}
                                                        />

                                                        <Chip
                                                            label={
                                                                file.fileType
                                                            }
                                                            style={{
                                                                backgroundColor:
                                                                    file.fileType ===
                                                                    "Formation"
                                                                        ? "#FFCDD2"
                                                                        : file.fileType ===
                                                                          "Play"
                                                                        ? "#A5D6A7"
                                                                        : file.fileType ===
                                                                          "vsFormation"
                                                                        ? "#E57373"
                                                                        : "#FFECB3",
                                                            }}
                                                        />
                                                    </Grid>{" "}
                                                    <Grid
                                                        size={9}
                                                        display="flex"
                                                        flexDirection="column"
                                                        justifyContent="left"
                                                        alignItems="left"
                                                    >
                                                        <TreeItem2
                                                            itemId={
                                                                file.playbookId
                                                            }
                                                            label={
                                                                <Box
                                                                    overflow={
                                                                        "scroll"
                                                                    }
                                                                >
                                                                    <Typography variant="h6">
                                                                        {label}
                                                                    </Typography>
                                                                </Box>
                                                            }
                                                            onClick={() => {
                                                                navigate(
                                                                    `/play-designer/${file.playbookId}`
                                                                );
                                                                setOpen(false);
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        size={0.5}
                                                        display="flex"
                                                        flexDirection="column"
                                                        justifyContent="center"
                                                        alignItems="center"
                                                    >
                                                        <IconButton
                                                            color="error"
                                                            onClick={() =>
                                                                deleteFile({
                                                                    deleteId:
                                                                        file.playbookId,
                                                                })
                                                            }
                                                        >
                                                            {deletingIds.some(
                                                                (id) =>
                                                                    id ===
                                                                    file.playbookId
                                                            ) ? (
                                                                <CircularProgress />
                                                            ) : (
                                                                <IconTrash />
                                                            )}
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            )
                                        );
                                    })}
                            </SimpleTreeView>
                        </Box>
                    </Box>
                )}
            </Box>
        </Box>
    );
}
