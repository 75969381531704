import {
    Button,
    Menu,
    MenuItem,
    Box,
    Typography,
    CircularProgress,
    Divider,
    Skeleton,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
    IconUserFilled,
    IconLogout,
    IconUsersGroup,
} from "@tabler/icons-react";
import { Link } from "react-router-dom";

export default function AccountDropDown({
    user,
    setUser,
    handleLogOut,

    anchorEl,
    setAnchorEl,
}) {
    return (
        <>
            {user.isAuthorized && (
                <>
                    <Button
                        sx={{
                            marginRight: "10px",
                            color: "black",
                        }}
                        onClick={(e) => {
                            setAnchorEl({ ...anchorEl, root: e.currentTarget });
                        }}
                    >
                        <IconUserFilled />
                    </Button>

                    <Menu
                        anchorEl={anchorEl.root}
                        open={Boolean(anchorEl.root)}
                        onClose={() => {
                            setAnchorEl({ root: null, organization: null });
                        }}
                        slotProps={{
                            paper: {
                                elevation: 0,
                                sx: {
                                    overflow: "visible",
                                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                    mt: 1.5,
                                    "& .MuiAvatar-root": {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                    },
                                    "&::before": {
                                        content: '""',
                                        display: "block",
                                        position: "absolute",
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        bgcolor: "background.paper",
                                        transform:
                                            "translateY(-50%) rotate(45deg)",
                                        zIndex: 0,
                                    },
                                },
                            },
                        }}
                        transformOrigin={{
                            horizontal: "right",
                            vertical: "top",
                        }}
                        anchorOrigin={{
                            horizontal: "right",
                            vertical: "bottom",
                        }}
                    >
                        <Box minWidth="260px">
                            <MenuItem>
                                <Grid container spacing={2} width="100%">
                                    <Grid size={2}>
                                        <IconUserFilled />
                                    </Grid>
                                    <Grid size={10}>
                                        <Link
                                            to="/account"
                                            style={{
                                                textDecoration: "none",
                                                color: "black",
                                            }}
                                        >
                                            Account
                                        </Link>
                                    </Grid>
                                </Grid>
                            </MenuItem>
                            {user.isFetchedOrganizations ? (
                                <>
                                    <MenuItem
                                        onClick={(e) => {
                                            setAnchorEl({
                                                ...anchorEl,
                                                organization: e.currentTarget,
                                            });
                                        }}
                                    >
                                        <Grid
                                            container
                                            spacing={2}
                                            width="100%"
                                        >
                                            <Grid size={2}>
                                                <IconUsersGroup />
                                            </Grid>
                                            <Grid size={9}>
                                                {user.activeOrganization ? (
                                                    <Typography>
                                                        {
                                                            user
                                                                .activeOrganization
                                                                .name
                                                        }
                                                    </Typography>
                                                ) : (
                                                    <Skeleton />
                                                )}
                                            </Grid>
                                            <Grid size={1}>
                                                <Typography>+</Typography>
                                            </Grid>
                                        </Grid>
                                    </MenuItem>
                                    <span>
                                        <Menu
                                            anchorEl={anchorEl.organization}
                                            open={Boolean(
                                                anchorEl.organization
                                            )}
                                            onClose={() => {
                                                setAnchorEl({
                                                    root: null,
                                                    organization: null,
                                                });
                                            }}
                                            slotProps={{
                                                paper: {
                                                    elevation: 0,
                                                    sx: {
                                                        overflow: "visible",
                                                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                                        mt: 1.5,
                                                        "& .MuiAvatar-root": {
                                                            width: 32,
                                                            height: 32,
                                                            ml: -0.5,
                                                            mr: 1,
                                                        },
                                                        "&::before": {
                                                            content: '""',
                                                            display: "block",
                                                            position:
                                                                "absolute",
                                                            top: 0,
                                                            right: 14,
                                                            width: 10,
                                                            height: 10,
                                                            bgcolor:
                                                                "background.paper",
                                                            transform:
                                                                "translateY(-50%) rotate(45deg)",
                                                            zIndex: 0,
                                                        },
                                                    },
                                                },
                                            }}
                                            transformOrigin={{
                                                horizontal: "right",
                                                vertical: "top",
                                            }}
                                            anchorOrigin={{
                                                horizontal: "right",
                                                vertical: "bottom",
                                            }}
                                        >
                                            {user.organizations.map(
                                                (organization) => (
                                                    <MenuItem
                                                        onClick={() => {
                                                            setUser({
                                                                ...user,
                                                                activeOrganization:
                                                                    organization.tenant,
                                                            });

                                                            setAnchorEl({
                                                                root: null,
                                                                organization:
                                                                    null,
                                                            });
                                                        }}
                                                    >
                                                        <Grid
                                                            container
                                                            spacing={2}
                                                            width="100%"
                                                        >
                                                            <Grid size={2}>
                                                                <IconUsersGroup />
                                                            </Grid>
                                                            <Grid size={10}>
                                                                <Typography>
                                                                    {
                                                                        organization
                                                                            .tenant
                                                                            .name
                                                                    }
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </MenuItem>
                                                )
                                            )}
                                        </Menu>
                                    </span>
                                </>
                            ) : (
                                <CircularProgress />
                            )}
                            <Divider />
                            <MenuItem onClick={handleLogOut}>
                                <Grid container spacing={2} width="100%">
                                    <Grid size={2}>
                                        <IconLogout />
                                    </Grid>
                                    <Grid size={10}>
                                        <Typography>Sign Out</Typography>
                                    </Grid>
                                </Grid>
                            </MenuItem>
                        </Box>
                    </Menu>
                </>
            )}
        </>
    );
}
