import React, { useState } from "react";
import { Menu, MenuItem, Typography, Grid2 } from "@mui/material";
import { v4 as uuidv4 } from "uuid";

import { IconCheck } from "@tabler/icons-react";

import FillMenu from "./FillMenu";
import ColorMenu from "./ColorMenu";

export default function ContextMenu({
    contextMenu,
    setContextMenu,
    state,
    setState,
    undoStack,
    redoStack,
    setUndoStack,
    setRedoStack,
    clipboard,
    setClipboard,
    direction,
    losLocation,
}) {
    const [fillMenuAnchorEl, setFillMenuAnchorEl] = useState(null);
    const [colorMenuAnchorEl, setColorMenuAnchorEl] = useState(null);

    const handleColorMenuOpen = (event) => {
        setColorMenuAnchorEl(event.currentTarget);
    };

    const handleColorMenuClose = (event) => {
        setColorMenuAnchorEl(null);
    };

    const handleFillMenuOpen = (event) => {
        setFillMenuAnchorEl(event.currentTarget);
    };

    const handleFillMenuClose = () => {
        setFillMenuAnchorEl(null);
    };

    const handleClose = () => {
        setContextMenu(null);
        handleFillMenuClose();
        setColorMenuAnchorEl(null);
    };
    return (
        contextMenu && (
            <Menu //Root
                anchorReference="anchorPosition"
                anchorPosition={
                    contextMenu
                        ? { top: contextMenu.y, left: contextMenu.x }
                        : undefined
                }
                open={Boolean(contextMenu)}
                onClose={handleClose}
                sx={{ width: "100%" }}
            >
                <MenuItem //Cut
                    disabled={!contextMenu.shape}
                    onClick={() => {
                        handleClose();
                        setRedoStack([]);
                        setUndoStack([
                            ...undoStack,
                            {
                                shapes: state.shapes,
                                direction: direction.current,
                                losLocation,
                                formation: state.formation,
                                play: state.play,
                                vsFormation: state.vsFormation,
                                vsPlay: state.vsPlay,
                                notes: state.notes,
                                positionGroup: state.positionGroup,
                                fileType: state.fileType,
                            },
                        ]);
                        setClipboard(
                            state.shapes.filter((shape) => {
                                return (
                                    shape.id === contextMenu.shape.id ||
                                    shape.selected
                                );
                            })
                        );
                        setState({
                            ...state,
                            shapes: state.shapes.filter((shape) => {
                                return (
                                    shape.id !== contextMenu.shape.id &&
                                    !shape.selected
                                );
                            }),
                        });
                    }}
                >
                    Cut
                </MenuItem>
                <MenuItem //Copy
                    disabled={!contextMenu.shape}
                    onClick={() => {
                        handleClose();
                        setRedoStack([]);
                        setUndoStack([
                            ...undoStack,
                            {
                                shapes: state.shapes,
                                direction: direction.current,
                                losLocation,
                                formation: state.formation,
                                play: state.play,
                                vsFormation: state.vsFormation,
                                vsPlay: state.vsPlay,
                                notes: state.notes,
                                positionGroup: state.positionGroup,
                                fileType: state.fileType,
                            },
                        ]);
                        setClipboard(
                            state.shapes.filter((shape) => {
                                return (
                                    shape.id === contextMenu.shape.id ||
                                    shape.selected
                                );
                            })
                        );
                    }}
                >
                    Copy
                </MenuItem>
                <MenuItem //Paste
                    onClick={() => {
                        handleClose();
                        setRedoStack([]);
                        setUndoStack([
                            ...undoStack,
                            {
                                shapes: state.shapes,
                                direction: direction.current,
                                losLocation,
                                formation: state.formation,
                                play: state.play,
                                vsFormation: state.vsFormation,
                                vsPlay: state.vsPlay,
                                notes: state.notes,
                                positionGroup: state.positionGroup,
                                fileType: state.fileType,
                            },
                        ]);
                        setState({
                            ...state,
                            shapes: [
                                ...state.shapes,
                                ...clipboard.map((shape) => {
                                    return {
                                        ...shape,
                                        id: uuidv4(),
                                    };
                                }),
                            ],
                        });
                    }}
                >
                    <Typography>Paste</Typography>
                </MenuItem>
                {["circle", "rect", "triangle"].some((s) => {
                    return s === contextMenu.shape?.shape;
                }) && (
                    <MenuItem //Border
                        disabled={!contextMenu.shape}
                        onClick={() => {
                            handleClose();
                            setState({
                                ...state,
                                shapes: state.shapes.map((shape) => {
                                    return shape.id === contextMenu.shape.id
                                        ? {
                                              ...shape,
                                              border: !shape.border,
                                          }
                                        : shape;
                                }),
                            });
                        }}
                    >
                        <Grid2
                            container
                            sx={{ width: "100%" }}
                            display={"flex"}
                            alignItems={"center"}
                        >
                            <Grid2 size={11}>
                                <Typography>Border</Typography>
                            </Grid2>
                            <Grid2 size={1}>
                                {contextMenu.shape &&
                                    contextMenu.shape.border && (
                                        <IconCheck width="15px" height="15px" />
                                    )}
                            </Grid2>
                        </Grid2>
                    </MenuItem>
                )}

                {["circle", "rect", "triangle"].some((s) => {
                    return s === contextMenu.shape?.shape;
                }) && (
                    <FillMenu
                        contextMenu={contextMenu}
                        fillMenuAnchorEl={fillMenuAnchorEl}
                        handleFillMenuClose={handleFillMenuClose}
                        handleFillMenuOpen={handleFillMenuOpen}
                        state={state}
                        setState={setState}
                    />
                )}
                <ColorMenu
                    contextMenu={contextMenu}
                    colorMenuAnchorEl={colorMenuAnchorEl}
                    handleColorMenuClose={handleColorMenuClose}
                    handleColorMenuOpen={handleColorMenuOpen}
                    state={state}
                    setState={setState}
                />
                <MenuItem
                    disabled={!contextMenu.shape}
                    onClick={() => {
                        handleClose();
                        setRedoStack([]);
                        setUndoStack([
                            ...undoStack,
                            {
                                shapes: state.shapes,
                                direction: direction.current,
                                losLocation,
                                formation: state.formation,
                                play: state.play,
                                vsFormation: state.vsFormation,
                                vsPlay: state.vsPlay,
                                notes: state.notes,
                                positionGroup: state.positionGroup,
                                fileType: state.fileType,
                            },
                        ]);
                        setState({
                            ...state,
                            shapes: state.shapes.filter((shape) => {
                                return shape.id !== contextMenu.shape.id;
                            }),
                        });
                    }}
                >
                    <Typography color="error">Delete</Typography>
                </MenuItem>
            </Menu>
        )
    );
}
