import { Box } from "@mui/material";
import ReactPlayer from "react-player";
import Button from "@mui/material/Button";
import {
    IconPlayerSkipBack,
    IconPlayerTrackPrev,
    IconPlayerEject,
    IconPlayerPlay,
    IconPlayerSkipForward,
    IconPlayerTrackNext,
} from "@tabler/icons-react";

const FilmRoom = () => {
    return (
        <>
            <Box display="flex" justifyContent="center" alignItems="center">
                <Box width="2000px" height="1400px">
                    <Box>
                        {" "}
                        <Box border="1px solid black" borderBottom={"none"}>
                            <ReactPlayer
                                url={
                                    "https://film.dev.clipr.ca/processed/6916d2ab-7bc7-40d2-bcd8-a4b7f2a6505c/47db03ab-3f42-440b-b2dc-ee90d19bbf4c/Wide%20-%20Clip%20001.mp4"
                                }
                                width="100%"
                                height="100%"
                            />
                        </Box>
                        <Box
                            width="100%"
                            height="64px"
                            border="1px solid black"
                        >
                            <Button
                                style={{
                                    bgcolor: "grey",
                                    width: "64px",
                                    height: "64px",
                                    color: "black",
                                    border: "none",
                                }}
                                onClick={() => {}}
                            >
                                <IconPlayerSkipBack
                                    color={"black"}
                                    width="64px"
                                    height="64px"
                                />
                            </Button>
                            <Button
                                style={{
                                    bgcolor: "grey",
                                    width: "64px",
                                    height: "64px",
                                    color: "black",
                                    border: "none",
                                }}
                                onClick={() => {}}
                            >
                                <IconPlayerTrackPrev
                                    color={"black"}
                                    width="64px"
                                    height="64px"
                                />
                            </Button>
                            <Button
                                style={{
                                    bgcolor: "grey",
                                    width: "64px",
                                    height: "64px",
                                    color: "black",
                                    border: "none",
                                }}
                                onClick={() => {}}
                            >
                                <IconPlayerEject
                                    color={"black"}
                                    width="64px"
                                    height="64px"
                                    style={{ transform: "rotate(-90deg)" }}
                                />
                            </Button>
                            <Button
                                style={{
                                    bgcolor: "grey",
                                    width: "64px",
                                    height: "64px",
                                    color: "black",
                                    border: "none",
                                }}
                                onClick={() => {}}
                            >
                                <IconPlayerPlay
                                    color={"black"}
                                    width="64px"
                                    height="64px"
                                />
                            </Button>
                            <Button
                                style={{
                                    bgcolor: "grey",
                                    width: "64px",
                                    height: "64px",
                                    color: "black",
                                    border: "none",
                                }}
                                onClick={() => {}}
                            >
                                <IconPlayerEject
                                    color={"black"}
                                    width="64px"
                                    height="64px"
                                    style={{ transform: "rotate(90deg)" }}
                                />
                            </Button>
                            <Button
                                style={{
                                    bgcolor: "grey",
                                    width: "64px",
                                    height: "64px",
                                    color: "black",
                                    border: "none",
                                }}
                                onClick={() => {}}
                            >
                                <IconPlayerTrackNext
                                    color={"black"}
                                    width="64px"
                                    height="64px"
                                />
                            </Button>
                            <Button
                                style={{
                                    bgcolor: "grey",
                                    width: "64px",
                                    height: "64px",
                                    color: "black",
                                    border: "none",
                                }}
                                onClick={() => {}}
                            >
                                <IconPlayerSkipForward
                                    color={"black"}
                                    width="64px"
                                    height="64px"
                                />
                            </Button>
                        </Box>{" "}
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default FilmRoom;
