import React, { useState, useEffect } from "react";
import { Stage, Layer, Rect } from "react-konva";
import { Box } from "@mui/material";
import PermanentFieldMarkings from "./PermanentFieldMarkings/index.js";
import Drawing from "./Drawing";
import { handleMouseDown, handleMouseMove, handleMouseUp } from "./handleMouse";
import ContextMenu from "./ContextMenu";

const LineOfScrimmage = ({ fieldDimensions, location }) => {
    const y = fieldDimensions.height * (location / 150);
    return (
        <>
            <Rect
                x={0}
                y={y}
                width={fieldDimensions.width}
                height={fieldDimensions.height * (0.25 / 150)}
                fill="black"
            />
        </>
    );
};

const Field = ({
    editingMode,
    state,
    setState,
    isAddingLine,
    undoStack,
    setUndoStack,
    redoStack,
    setRedoStack,
    previewedShape,
    setPreviewedShape,
    isDrawing,
    anchoredPreviewedLine,
    contextMenu,
    setContextMenu,
    clipboard,
    setClipboard,
    losLocation,
    setLosLocation,
    direction,
    playId,
    fieldDimensions,
    stageDimensions,
    fetchState,
    setFetchState,
}) => {
    const [hoveredId, setHoveredId] = useState(null);
    const [lasooSelection, setLasooSelection] = useState({
        startPos: null,
        isSelecting: false,
        selectionRect: null,
    });

    const isAddingZone = React.useRef(false);

    useEffect(() => {
        const stage = document.querySelector(".konvajs-content");
        if (stage) {
            if (!state.editing) {
                stage.style.cursor = "default";
            } else if (
                editingMode === "drawing" ||
                editingMode === "addingLine" ||
                editingMode === "addingRectZone" ||
                editingMode === "addingOvalZone"
            ) {
                stage.style.cursor = "crosshair";
            } else if (hoveredId) {
                stage.style.cursor = "move";
            }
        }
        return () => {
            if (stage) {
                stage.style.cursor = "default"; // Reset to default cursor on cleanup
            }
        };
    }, [editingMode, state.editing, state.cursor, hoveredId]);

    useEffect(() => {
        // Initialize offensive line shapes
        if (playId !== "new") return;
        if (fieldDimensions.width === 0) return;
        if (fieldDimensions.height === 0) return;
        if (
            state.shapes.length > 0 ||
            undoStack.length > 0 ||
            redoStack.length > 0
        )
            return;

        const x = 24.5;
        const y = losLocation + 0.6;

        const offensiveLineShapes = [
            {
                id: "left-tackle",
                shape: "circle",
                x: x - 4,
                y,
                radius: 0.625,
                fill: "empty",
                color: "black",
                border: true,
                label: "LT",
                selected: false,
            },
            {
                id: "left-guard",
                shape: "circle",
                x: x - 2,
                y,
                radius: 0.625,
                fill: "empty",
                color: "black",
                border: true,
                label: "LG",
                selected: false,
            },
            {
                id: "center",
                shape: "rect",
                x: x - 0.625,
                y: y - 0.6,
                radius: 0.625,
                fill: "empty",
                color: "black",
                border: true,
                label: "C",
                selected: false,
            },
            {
                id: "right-guard",
                shape: "circle",
                x: x + 2,
                y,
                radius: 0.625,
                fill: "empty",
                color: "black",
                border: true,
                label: "RG",
                selected: false,
            },
            {
                id: "right-tackle",
                shape: "circle",
                x: x + 4,
                y,
                radius: 0.625,
                fill: "empty",
                color: "black",
                border: true,
                label: "RT",
                selected: false,
            },
        ];

        setState((prevState) => ({
            ...prevState,
            shapes: [...prevState.shapes, ...offensiveLineShapes],
        }));
    }, [
        fieldDimensions,
        setState,
        state.shapes.length,
        redoStack.length,
        undoStack.length,
        losLocation,
        playId,
    ]);

    useEffect(() => {
        function handleKeyDown(e) {
            if (e.key === "Escape") {
                isAddingLine.current = false;
                isDrawing.current = false;
                anchoredPreviewedLine.current = null;
                setState((prevState) => ({
                    ...prevState,
                    editingMode: "selecting",
                    cursor: "default",
                    shapes: prevState.shapes.map((shape) => ({
                        ...shape,
                        selected: false,
                    })),
                }));
                setPreviewedShape(null);
            }
        }

        document.addEventListener("keydown", handleKeyDown);

        // Don't forget to clean up
        return function cleanup() {
            document.removeEventListener("keydown", handleKeyDown);
        };
    });

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            overflow="hidden"
            position="absolute"
            top="100"
        >
            <Box
                width={{
                    xs: "90vw",
                    sm: "600px",
                    md: "900px",
                    lg: "1200px",
                    xl: "1500px",
                }}
                position="relative"
                paddingTop={{
                    xs: "calc(90vw * 9 / 16)",
                    sm: "calc(600px * 9 / 16)",
                    md: "calc(900px * 9 / 16)",
                    lg: "calc(1200px * 9 / 16)",
                    xl: "calc(1500px * 9 / 16)",
                }}
                overflow="hidden"
            >
                <Box
                    position="absolute"
                    top="0"
                    left="0"
                    width="100%"
                    height="100%"
                    border="1px solid black"
                    borderLeft="none"
                >
                    <Stage
                        width={stageDimensions.width}
                        height={stageDimensions.height}
                        onMouseMove={(e) =>
                            handleMouseMove({
                                e,
                                state,
                                setState,
                                isDrawing,
                                isAddingLine,
                                hoveredId,
                                setHoveredId,
                                editingMode,
                                fieldDimensions,
                                lasooSelection,
                                setLasooSelection,
                                redoStack,
                                setRedoStack,
                                previewedShape,
                                setPreviewedShape,
                                anchoredPreviewedLine,
                                isAddingZone,
                            })
                        }
                        onMouseDown={(e) =>
                            handleMouseDown({
                                e,
                                state,
                                setState,
                                isDrawing,
                                editingMode,
                                fieldDimensions,
                                lasooSelection,
                                setLasooSelection,
                                isAddingLine,
                                undoStack,
                                setUndoStack,
                                redoStack,
                                setRedoStack,
                                previewedShape,
                                setPreviewedShape,
                                anchoredPreviewedLine,
                                direction,
                                losLocation,
                                hoveredId,
                                isAddingZone,
                            })
                        }
                        onMouseUp={(e) =>
                            handleMouseUp({
                                e,
                                state,
                                setState,
                                isDrawing,
                                editingMode,
                                lasooSelection,
                                setLasooSelection,
                                fieldDimensions,
                                redoStack,
                                setRedoStack,
                                clipboard,
                                previewedShape,
                                setPreviewedShape,
                                hoveredId,
                                isAddingZone,
                                undoStack,
                                setUndoStack,
                                direction,
                                losLocation,
                            })
                        }
                        onContextMenu={(e) => {
                            e.evt.preventDefault();
                            const stage = e.target.getStage();
                            const shape = stage.getIntersection(
                                stage.getPointerPosition()
                            );
                            if (!shape || !shape.id()) {
                                setContextMenu({
                                    shape: null,
                                    x: e.evt.clientX,
                                    y: e.evt.clientY,
                                });
                            }
                        }}
                        x={0}
                        y={
                            direction.current === "north"
                                ? -fieldDimensions.height *
                                  ((losLocation - 26.5) / 150)
                                : -fieldDimensions.height *
                                  ((losLocation - 10) / 150)
                        }
                    >
                        <Layer scaleX={1} scaleY={1} opacity={0.4}>
                            <PermanentFieldMarkings
                                fieldDimensions={fieldDimensions}
                            />
                        </Layer>
                        <Layer scaleX={1} scaleY={1}>
                            <LineOfScrimmage
                                fieldDimensions={fieldDimensions}
                                location={losLocation}
                            />

                            <Drawing
                                fieldDimensions={fieldDimensions}
                                state={state}
                                setState={setState}
                                hoveredId={hoveredId}
                                editingMode={editingMode}
                                undoStack={undoStack}
                                setUndoStack={setUndoStack}
                                redoStack={redoStack}
                                setRedoStack={setRedoStack}
                                previewedShape={previewedShape}
                                setPreviewedShape={setPreviewedShape}
                                contextMenu={contextMenu}
                                setContextMenu={setContextMenu}
                                losLocation={losLocation}
                                setLosLocation={setLosLocation}
                                direction={direction}
                            />
                            {state.editing &&
                                editingMode === "selecting" &&
                                lasooSelection.isSelecting &&
                                lasooSelection.selectionRect && (
                                    <Rect
                                        x={lasooSelection.selectionRect.x}
                                        y={lasooSelection.selectionRect.y}
                                        width={
                                            lasooSelection.selectionRect.width
                                        }
                                        height={
                                            lasooSelection.selectionRect.height
                                        }
                                        stroke="black"
                                        dash={[4, 4]}
                                        strokeWidth={1}
                                        fill="rgba(0,0,0,0.1)"
                                    />
                                )}
                        </Layer>
                    </Stage>
                    <ContextMenu
                        contextMenu={contextMenu}
                        setContextMenu={setContextMenu}
                        state={state}
                        setState={setState}
                        undoStack={undoStack}
                        setUndoStack={setUndoStack}
                        redoStack={redoStack}
                        setRedoStack={setRedoStack}
                        clipboard={clipboard}
                        setClipboard={setClipboard}
                        direction={direction}
                        losLocation={losLocation}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default Field;
